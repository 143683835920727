































import { Component, Prop, Vue } from 'vue-property-decorator';

import type { NewsFeedWithoutImageVM } from '@jtnews/shared/seedwork/frontend/application';
import { UiObserveVisibility, UiAdvLabel } from '@jtnews/shared/ui';

@Component({
  name: 'NewsFeedBlockItem',
  components: { UiAdvLabel, UiObserveVisibility },
})
export default class NewsFeedBlockItem extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  item!: NewsFeedWithoutImageVM;

  @Prop({
    required: true,
    type: String,
  })
  place!: string;

  emitArticleClicked() {
    this.$emit('articleClicked', {
      currentPlace: this.place,
      id: this.item.id,
      isCommercial: this.item.isCommercial,
    });
  }

  emitNewsViewed() {
    this.$emit('newsViewed', {
      currentPlace: this.place,
      id: this.item.id,
      isCommercial: this.item.isCommercial,
    });
  }
}
