import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

type Article = {
  id: number;
  isCommercial: boolean;
};

type AnalyticsData = {
  place: string;
  type: 'window' | 'feed';
};

type ArticleClick = {
  data: Article;
  clickPlace: string;
  analyticsData: AnalyticsData;
};

enum ActionType {
  View = 'Просмотр',
  Play = 'Запуск видео',
  Click = 'Переход на материал',
  Comment = 'Переход на комментарии',
  Taxonomy = 'Переход на тему/формат/рубрику',
}

enum GoalName {
  View = 'viewShowcase',
  Play = 'playShowcase',
  ViewMain = 'viewMainNews',
  ClickMain = 'clickMainNews',
  Click = 'clickShowcase',
  ViewFeed = 'viewNewsFeed',
  ClickFeed = 'clickNewsFeed',
}

export type WindowBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface IWindowBlockAnalyticsService extends IAnalyticsService {
  sendEventArticleClick(input: ArticleClick): void;
  sendEventBlockShown(data: Article, analyticsData: AnalyticsData): void;
  sendEventVideoPlay(data: Article, analyticsData: AnalyticsData): void;
  sendEventCommentClick(data: Article, analyticsData: AnalyticsData): void;
  sendEventMainNewsClick(id: number): void;
  sendEventMainNewsShown(id: number): void;
  sendEventTaxonomyClick(data: Article, analyticsData: AnalyticsData): void;
}

export class WindowBlockAnalyticsService
  extends AnalyticsService
  implements IWindowBlockAnalyticsService
{
  private readonly _blockName = 'Витрина';

  constructor(config: WindowBlockAnalyticsServiceConfig) {
    super(config);
  }

  sendEventArticleClick(input: ArticleClick): void {
    const {
      data,
      clickPlace,
      analyticsData: { type, place },
    } = input;

    const goalName = type === 'window' ? GoalName.Click : GoalName.ClickFeed;
    const articleType = data.isCommercial ? 'Рекламный' : 'Редакционный';
    const valueName = {
      [place]: {
        [ActionType.Click]: {
          [clickPlace]: {
            [articleType]: `${data.id}`,
          },
        },
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName,
        goalName,
      },
    });
  }

  sendEventBlockShown(data: Article, analyticsData: AnalyticsData): void {
    const { type, place } = analyticsData;

    const goalName = type === 'window' ? GoalName.View : GoalName.ViewFeed;
    const articleType = data.isCommercial ? 'Рекламный' : 'Редакционный';
    const valueName = {
      [place]: {
        [ActionType.View]: {
          [articleType]: `${data.id}`,
        },
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName,
        goalName,
      },
    });
  }

  sendEventVideoPlay(data: Article, analyticsData: AnalyticsData): void {
    const { place } = analyticsData;

    const articleType = data.isCommercial ? 'Рекламный' : 'Редакционный';
    const valueName = {
      [place]: {
        [ActionType.Play]: {
          [articleType]: `${data.id}`,
        },
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName,
        goalName: GoalName.Play,
      },
    });
  }

  sendEventCommentClick(data: Article, analyticsData: AnalyticsData): void {
    const { type, place } = analyticsData;

    const goalName = type === 'window' ? GoalName.Click : GoalName.ClickFeed;
    const articleType = data.isCommercial ? 'Рекламный' : 'Редакционный';

    const valueName = {
      [place]: {
        [ActionType.Comment]: { [articleType]: `${data.id}` },
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName,
        goalName,
      },
    });
  }

  sendEventMainNewsClick(id: number): void {
    const valueName = {
      [ActionType.Click]: id.toString(),
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: 'Главная новость',
        valueName,
        goalName: GoalName.ClickMain,
      },
    });
  }

  sendEventMainNewsShown(id: number): void {
    const valueName = {
      [ActionType.View]: id.toString(),
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: 'Главная новость',
        valueName,
        goalName: GoalName.ViewMain,
      },
    });
  }

  sendEventTaxonomyClick(data: Article, analyticsData: AnalyticsData): void {
    const { type, place } = analyticsData;
    const articleType = data.isCommercial ? 'Рекламный' : 'Редакционный';

    const goalName = type === 'window' ? GoalName.Click : GoalName.ClickFeed;
    const valueName = {
      [place]: {
        [ActionType.Taxonomy]: { [articleType]: `${data.id}` },
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName,
        goalName,
      },
    });
  }
}
