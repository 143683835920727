


















import { Component, Prop, Vue, Inject } from 'vue-property-decorator';

import type {
  IReportageBlockUseCase,
  ReportageBlockVM,
} from '@jtnews/shared/seedwork/frontend/application';
import { REPORTAGE_BLOCK_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import { type BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';
import { UiNewsGroup, UiBaseBlock } from '@jtnews/shared/ui';

@Component({
  name: 'ReportageBlock',
  components: {
    UiNewsGroup,
    UiBaseBlock,
  },
})
export default class ReportageBlock extends Vue {
  @Prop({
    required: true,
    type: Object as () => ReportageBlockVM,
  })
  block!: ReportageBlockVM;

  @Prop({
    required: true,
    type: Object as () => BlockPlaceRepositoryConfig,
  })
  blockPlaceConfig!: BlockPlaceRepositoryConfig;

  @Prop({
    required: true,
    type: Boolean,
  })
  isMobile!: boolean;

  @Inject(REPORTAGE_BLOCK_USE_CASE_KEY)
  private readonly _useCase!: IReportageBlockUseCase;

  get params() {
    return this.block.params;
  }

  get items() {
    const MOBILE_AMOUNT = 5;
    const amount = this.isMobile ? MOBILE_AMOUNT : 1;
    return this.block.data
      .map((item) => ({
        ...item,
        isBookmarked: this._useCase.hasUserBookmark(item.id),
      }))
      .slice(0, amount);
  }

  created() {
    this._useCase.setBlockPlace(this.blockPlaceConfig);
  }

  processBlockShown() {
    this._useCase.processBlockShown(this.items);
  }

  processArticleClick({ articleId, place }: { articleId: number; place: string }) {
    this._useCase.processArticleClick(articleId, place);
  }

  processCommentClick({ articleId }: { event: Event; articleId: number }): void {
    this._useCase.processCommentClick(articleId);
  }

  processBookmarkClick({ articleId }: { articleId: number }): void {
    this._useCase.processBookmarkClick(articleId);
  }
}
