import { render, staticRenderFns } from "./articles-by-rubric.page.vue?vue&type=template&id=89714b08&"
import script from "./articles-by-rubric.page.vue?vue&type=script&lang=ts&"
export * from "./articles-by-rubric.page.vue?vue&type=script&lang=ts&"
import style0 from "./articles-by-rubric.page.vue?vue&type=style&index=0&id=89714b08&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports