import { INewsFeedSingleAnalyticsService } from '@jtnews/home/frontend/domain';
import {
  BlockUseCase,
  IBlockUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import type {
  BlockUseCaseConfig,
  NewsFeedVM,
  IBookmarksUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';

type ArticleClick = {
  data: NewsFeedVM;
  clickPlace: string;
  place: string;
};

export const NEWS_FEED_SINGLE_USE_CASE_KEY = 'news_feed_single_use_case_key';

export interface INewsFeedSingleUseCase extends IBlockUseCase {
  hasUserBookmark(articleId: number): boolean;
  processArticleClick(input: ArticleClick): void;
  processBlockShown(data: NewsFeedVM, place: string): void;
  processBookmarkClick(articleId: number): void;
  processCommentClick(data: NewsFeedVM, place: string): void;
}

export type NewsFeedSingleUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: INewsFeedSingleAnalyticsService;
    bookmarkUseCase: IBookmarksUseCase;
  };
};

export class NewsFeedSingleUseCase
  extends BlockUseCase
  implements INewsFeedSingleUseCase
{
  private readonly _analyticsService: INewsFeedSingleAnalyticsService;
  private readonly _bookmarkUseCase: IBookmarksUseCase;

  constructor(config: NewsFeedSingleUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService, bookmarkUseCase },
    } = config;

    this._analyticsService = analyticsService;
    this._bookmarkUseCase = bookmarkUseCase;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public hasUserBookmark(articleId: number) {
    return this._bookmarkUseCase.hasUserBookmark(articleId);
  }

  public processArticleClick(input: ArticleClick): void {
    this._analyticsService.sendEventArticleClick(input);
  }

  public processBlockShown(data: NewsFeedVM, place: string): void {
    this._analyticsService.sendEventBlockShown(data, place);
  }

  public processCommentClick(data: NewsFeedVM, place: string): void {
    this._analyticsService.sendEventCommentClick(data, place);
  }

  public processBookmarkClick(articleId: number): void {
    void this._bookmarkUseCase.toggle({
      articleId,
      type: 'record',
    });
  }
}
