import type { IPictureDayAnalyticsService } from '@jtnews/home/frontend/domain';
import { BlockUseCase } from '@jtnews/shared/seedwork/frontend/application';
import type {
  BlockUseCaseConfig,
  IBlockUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';

import { PictureDayArticleVM } from './picture-day-block.vm';

export const PICTURE_DAY_BLOCK_USE_CASE_KEY = 'picture_day_block_use_case_key';

export interface IPictureDayBlockUseCase extends IBlockUseCase {
  processArticleClick(tabName: string, item: PictureDayArticleVM): void;
  processBlockShown(tabName: string): void;
  processAllNewsButtonClick(tabName: string): void;
  processItemShown(tabName: string, item: PictureDayArticleVM): void;
  processHotNewsClick(articleId: number): void;
  processHotNewsShown(articleId: number): void;
  processTabClick(tabName: string): void;
}

export type PictureDayBlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IPictureDayAnalyticsService;
  };
};

export class PictureDayBlockUseCase
  extends BlockUseCase
  implements IPictureDayBlockUseCase
{
  private readonly _analyticsService: IPictureDayAnalyticsService;

  constructor(config: PictureDayBlockUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService },
    } = config;

    this._analyticsService = analyticsService;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processHotNewsClick(articleId: number): void {
    this._analyticsService.sendEventHotNewsClick(articleId);
  }

  public processHotNewsShown(articleId: number): void {
    this._analyticsService.sendEventHotNewsShown(articleId);
  }

  public processArticleClick(tabName: string, item: PictureDayArticleVM): void {
    this._analyticsService.sendEventArticleClick(tabName, item);
  }

  public processBlockShown(tabName: string): void {
    this._analyticsService.sendEventBlockShown(tabName);
  }
  public processAllNewsButtonClick(tabName: string): void {
    this._analyticsService.sendEventAllNewsClick(tabName);
  }
  public processItemShown(tabName: string, item: PictureDayArticleVM): void {
    this._analyticsService.sendEventItemShown(tabName, item);
  }

  public processTabClick(tabName: string): void {
    this._analyticsService.sendEventTabClick(tabName);
  }
}
