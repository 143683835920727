var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.page},[_c('UiLayout',{attrs:{"is-render-right-col":true,"is-render-left-col":false},scopedSlots:_vm._u([{key:"top-block",fn:function(){return [(_vm.articlesByRubricPageStoreFacade.content.menuThemes !== null)?_c('ThemesBlock',{class:_vm.$style.scrollableBlock,attrs:{"block":_vm.articlesByRubricPageStoreFacade.content.menuThemes}}):_vm._e()]},proxy:true},{key:"central-col",fn:function(){return [_c('UiPageBlockH1',{class:_vm.classes.width.full.mobile,attrs:{"title":_vm.header}}),_c('div',{class:[_vm.$style.content, _vm.classes.width.full.mobile]},[(_vm.isShowFixedRecord && _vm.fixedRecord)?_c('ArchiveArticleItem',{key:"fixed_record_0",attrs:{"is-mobile":_vm.isMobile,"item":_vm.fixedRecord.data}}):_vm._e(),_c('ArchiveArticles',{attrs:{"articles":_vm.articlesList,"is-mobile":_vm.isMobile}})],1),(_vm.isShowPagination)?_c('UiPagination',{class:_vm.classes.width.full.mobile,attrs:{"is-mobile":_vm.isMobile,"total-pages":_vm.filter.totalPages,"url":_vm.path,"value":_vm.filter.currentPage,"is-show-more-btn":false}}):_vm._e()]},proxy:true},{key:"right-col",fn:function(){return [_vm._l((_vm.rightCol.data),function(block,index){return [(block.type === 'adv')?[(_vm.adv && _vm.adv[block.id])?_c('AdvBlock',{key:((block.type) + "_" + index),class:[block.classes, _vm.$style.adv],attrs:{"is-central-column":true,"has-background":block.hasBackground,"has-padding":block.hasPadding,"cms-place":block.id,"block":_vm.adv[block.id],"container-id-postfix":block.containerIdPostfix}}):_vm._e()]:_vm._e(),(block.type === 'do')?[(_vm.articlesByRubricPageStoreFacade.content.do !== null)?_c('LazyBlock',{key:((block.type) + "_" + index),class:block.classes,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var canRender = ref.canRender;
return [(canRender)?_c('DoBlock',{attrs:{"block-place-config":block.blockPlaceConfig,"block":_vm.articlesByRubricPageStoreFacade.content.do}}):_vm._e()]}}],null,true)}):_vm._e()]:_vm._e(),(block.type === 'editorial_office')?[(_vm.articlesByRubricPageStoreFacade.content.feedback !== null)?_c('LazyBlock',{key:block.type,class:[block.classes, _vm.$style.feedback],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var canRender = ref.canRender;
return [(canRender)?_c('FeedbackBlock',{attrs:{"block-place-config":block.blockPlaceConfig,"block":_vm.articlesByRubricPageStoreFacade.content.feedback}}):_vm._e()]}}],null,true)}):_vm._e()]:_vm._e(),(block.type === 'love')?[(_vm.articlesByRubricPageStoreFacade.content.love !== null)?_c('LazyBlock',{key:block.type,class:block.classes,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var canRender = ref.canRender;
return [(canRender)?_c('LoveBlock',{attrs:{"block-place-config":block.blockPlaceConfig,"block":_vm.articlesByRubricPageStoreFacade.content.love}}):_vm._e()]}}],null,true)}):_vm._e()]:_vm._e(),(block.type === 'recent_opinions')?[(_vm.articlesByRubricPageStoreFacade.content.opinions !== null)?_c('LazyBlock',{key:block.type,class:block.classes,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var canRender = ref.canRender;
return [(canRender)?_c('OpinionsBlock',{attrs:{"block-place-config":block.blockPlaceConfig,"is-right-col":true,"block":_vm.articlesByRubricPageStoreFacade.content.opinions}}):_vm._e()]}}],null,true)}):_vm._e()]:_vm._e(),(block.type === 'recommendation')?[(_vm.articlesByRubricPageStoreFacade.content.recommendations !== null)?_c('LazyBlock',{key:block.type,class:block.classes,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var canRender = ref.canRender;
return [(canRender)?_c('RecommendationsBlock',{attrs:{"block-place-config":block.blockPlaceConfig,"block":_vm.articlesByRubricPageStoreFacade.content.recommendations}}):_vm._e()]}}],null,true)}):_vm._e()]:_vm._e(),(block.type === 'top_news')?[(_vm.articlesByRubricPageStoreFacade.content.topArticles !== null)?_c('LazyBlock',{key:block.type,class:block.classes,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var canRender = ref.canRender;
return [(canRender)?_c('TopArticlesBlock',{attrs:{"block-place-config":block.blockPlaceConfig,"block":_vm.articlesByRubricPageStoreFacade.content.topArticles}}):_vm._e()]}}],null,true)}):_vm._e()]:_vm._e()]})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }