



























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiImage } from '../../ui-image';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type { NewsDoubleCard } from './ui-news-double.contract';

@Component({
  components: {
    UiStatistic,
    UiObserveVisibility,
    UiImage,
  },
  name: 'UiNewsDouble',
})
export default class UiNewsDouble extends Vue {
  @Prop({
    type: Object as () => NewsDoubleCard,
    required: true,
  })
  newsCard!: NewsDoubleCard;

  @Emit('blockShown')
  blockShown(): void {}

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('articleClicked')
  articleClicked(place: string): string {
    return place;
  }
}
