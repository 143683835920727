import type { ISingleCardBlockAnalyticsService } from '@jtnews/home/frontend/domain';
import { BlockUseCase } from '@jtnews/shared/seedwork/frontend/application';
import type {
  BlockUseCaseConfig,
  IBlockUseCase,
  IBookmarksUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';

export const SINGLE_CARD_BLOCK_USE_CASE_KEY = 'single_card_block_use_case_key';

export interface ISingleCardBlockUseCase extends IBlockUseCase {
  processBlockShown(id: number): void;
  processBlockClick(id: number): void;
  processBookmarkClick(id: number): void;
  isBookmarked(id: number): boolean;
}

export type SingleCardBlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: ISingleCardBlockAnalyticsService;
    bookmarkUseCase: IBookmarksUseCase;
  };
};

export class SingleCardBlockUseCase
  extends BlockUseCase
  implements ISingleCardBlockUseCase
{
  private readonly _analyticsService: ISingleCardBlockAnalyticsService;
  private readonly _bookmarkUseCase: IBookmarksUseCase;

  constructor(config: SingleCardBlockUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService, bookmarkUseCase },
    } = config;

    this._analyticsService = analyticsService;
    this._bookmarkUseCase = bookmarkUseCase;
  }

  isBookmarked(id: number) {
    return this._bookmarkUseCase.hasUserBookmark(id);
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processBlockShown(id: number): void {
    this._analyticsService.sendEventBlockShown(id);
  }

  public processBlockClick(id: number): void {
    this._analyticsService.sendEventBlockClick(id);
  }

  public processBookmarkClick(articleId: number): void {
    void this._bookmarkUseCase.toggle({
      articleId,
      type: 'record',
    });
  }
}
