

























import { Component, Prop, Vue, Inject } from 'vue-property-decorator';

import type {
  SingleCardBlockVM,
  ISingleCardBlockUseCase,
} from '@jtnews/home/frontend/application';
import { SINGLE_CARD_BLOCK_USE_CASE_KEY } from '@jtnews/home/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';
import { UiOpinionCard, UiImage, UiButton } from '@jtnews/shared/ui';

@Component({
  name: 'SingleCardBlock',
  components: { UiOpinionCard, UiImage, UiButton },
})
export default class SingleCardBlock extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  block!: SingleCardBlockVM;

  @Prop({
    required: true,
    type: Object as () => BlockPlaceRepositoryConfig,
  })
  blockPlaceConfig!: BlockPlaceRepositoryConfig;

  @Inject(SINGLE_CARD_BLOCK_USE_CASE_KEY)
  private readonly _useCase!: ISingleCardBlockUseCase;

  get item() {
    return this.block.data;
  }

  get isBookmarked() {
    return this._useCase.isBookmarked(this.item.id);
  }

  get isQuote(): boolean {
    return this.block.data.type === 'quote';
  }

  created() {
    this._useCase.setBlockPlace(this.blockPlaceConfig);
  }

  onBlockShown() {
    this._useCase.processBlockShown(this.item.id);
  }

  onBlockClick() {
    this._useCase.processBlockClick(this.item.id);
  }

  processBookmarkClick() {
    this._useCase.processBookmarkClick(this.item.id);
  }
}
