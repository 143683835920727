





































































































































/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Inject, Provide, Vue } from 'vue-property-decorator';

import type { IArchiveArticlesUseCase } from '@jtnews/records/frontend/application';
import {
  ARCHIVE_ARTICLES_USE_CASE_KEY,
  ArchiveArticlesUseCase,
  ArticlesByDatePageStoreFacade,
} from '@jtnews/records/frontend/application';
import { ArchiveArticleAnalyticsService } from '@jtnews/records/frontend/domain';
import type {
  IBookmarksUseCase,
  ILogger,
} from '@jtnews/shared/seedwork/frontend/application';
import { LOGGER_KEY } from '@jtnews/shared/seedwork/frontend/application';
import type { IReachGoalAnalytics } from '@jtnews/shared/seedwork/frontend/domain';
import {
  type IBlockPlaceRepository,
  REACH_GOAL_ANALYTICS_KEY,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AdvBlock, ThemesBlock } from '@jtnews/shared/seedwork/frontend/features';
import { BLOCK_TYPE_REPOSITORY_KEY } from '@jtnews/shared/seedwork/frontend/infrastructure';
import {
  grid,
  UiLayout,
  UiPageBlockH1,
  UiPagination,
  LazyBlock,
} from '@jtnews/shared/ui';
import { BOOKMARKS_USE_CASE_KEY } from '@jtnews/users/frontend/api';

import { ArchiveArticles } from '../archive-articles';

/* eslint-disable @typescript-eslint/naming-convention */
const LoveBlock = () =>
  import(
    /* webpackChunkName: "love-block" */ '@jtnews/shared/seedwork/frontend/features/love-block'
  ).then((m) => m.LoveBlock);

const OpinionsBlock = () =>
  import(
    /* webpackChunkName: "opinions-block" */ '@jtnews/shared/seedwork/frontend/features/opinions-block'
  ).then((m) => m.OpinionsBlock);

const RecommendationsBlock = () =>
  import(
    /* webpackChunkName: "recommendations-block" */ '@jtnews/shared/seedwork/frontend/features/recommendations-block'
  ).then((m) => m.RecommendationsBlock);

const TopArticlesBlock = () =>
  import(
    /* webpackChunkName: "top-articles-block" */ '@jtnews/shared/seedwork/frontend/features/top-articles-block'
  ).then((m) => m.TopArticlesBlock);

const DoBlock = () =>
  import(
    /* webpackChunkName: "do-block" */ '@jtnews/shared/seedwork/frontend/features/do-block'
  ).then((m) => m.DoBlock);

const FeedbackBlock = () =>
  import(
    /* webpackChunkName: "feedback-block" */ '@jtnews/shared/seedwork/frontend/features/feedback-block'
  ).then((m) => m.FeedbackBlock);
/* eslint-enable @typescript-eslint/naming-convention */

@Component({
  name: 'ArticlesByDatePage',
  components: {
    ThemesBlock,
    AdvBlock,
    DoBlock,
    LoveBlock,
    FeedbackBlock,
    OpinionsBlock,
    RecommendationsBlock,
    TopArticlesBlock,
    UiPagination,
    ArchiveArticles,
    UiLayout,
    UiPageBlockH1,
    LazyBlock,
  },
})
export default class ArticlesByDatePage extends Vue {
  @Inject(LOGGER_KEY)
  logger!: ILogger;

  @Inject(REACH_GOAL_ANALYTICS_KEY)
  reachGoalAnalytics!: IReachGoalAnalytics;

  @Inject(BOOKMARKS_USE_CASE_KEY)
  bookmarkUseCase!: IBookmarksUseCase;

  @Inject(BLOCK_TYPE_REPOSITORY_KEY)
  blockPlaceRepository!: IBlockPlaceRepository;

  articlesByDatePageStoreFacade = new ArticlesByDatePageStoreFacade(this.$pinia);

  @Provide(ARCHIVE_ARTICLES_USE_CASE_KEY)
  archiveArticlesUseCase: IArchiveArticlesUseCase = new ArchiveArticlesUseCase({
    deps: {
      logger: this.logger,
      bookmarkUseCase: this.bookmarkUseCase,
      analyticsService: new ArchiveArticleAnalyticsService({
        pageName: this.articlesByDatePageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics,
        },
      }),
    },
  });

  get classes() {
    return grid;
  }

  get filter() {
    return this.articlesByDatePageStoreFacade.filter;
  }

  get isMobile() {
    return this.articlesByDatePageStoreFacade.isMobile;
  }

  get path() {
    return this.articlesByDatePageStoreFacade.metaSettings.path;
  }

  get header() {
    return this.articlesByDatePageStoreFacade.metatags.h1;
  }

  get adv() {
    return this.articlesByDatePageStoreFacade.content.adv;
  }

  get articlesList() {
    return this.articlesByDatePageStoreFacade.articlesList;
  }

  get rightCol() {
    return this.articlesByDatePageStoreFacade.grid.right;
  }

  get isShowPagination() {
    return this.filter.totalPages > 1;
  }
}
