









































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { LinkTargetVM } from '@jtnews/shared/seedwork/frontend/application';

import { UiImage } from '../../ui-image';
import { UiLabel } from '../../ui-label';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';
import { UiViqeoVideo } from '../../ui-viqeo-video';

import type {
  NewsPhotoImage,
  NewsPhotoStatistic,
  NewsPhotoLabel,
  NewsPhotoVideo,
} from './ui-news-photo.contract';

@Component({
  components: {
    UiViqeoVideo,
    UiLabel,
    UiStatistic,
    UiObserveVisibility,
    UiImage,
    UiNewsCardLabels,
  },
  name: 'UiNewsPhoto',
})
export default class UiNewsPhoto extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  url!: string;

  @Prop({
    type: String as () => LinkTargetVM,
    required: true,
  })
  target!: LinkTargetVM;

  @Prop({
    type: Object as () => NewsPhotoImage,
    required: true,
  })
  image!: NewsPhotoImage;
  @Prop({
    type: Object as () => NewsPhotoVideo,
    default: null,
  })
  video!: NewsPhotoVideo;

  @Prop({
    type: Object as () => NewsPhotoStatistic,
    required: true,
  })
  statistic!: NewsPhotoStatistic;

  @Prop({
    type: Object as () => NewsPhotoLabel,
    default: null,
  })
  label: NewsPhotoLabel;

  @Prop({
    type: Boolean,
    default: false,
  })
  isBookmarked: boolean;

  get hasLabel(): boolean {
    return guardUnspecified(this.label);
  }

  get hasVideo(): boolean {
    return guardUnspecified(this.video);
  }
  get hasImage(): boolean {
    return guardUnspecified(this.image) && !this.hasVideo;
  }

  commentClicked(event: Event) {
    this.$emit('commentClicked', event);
  }

  bookmarkClicked(event: Event) {
    this.$emit('bookmarkClicked', event);
  }

  articleClicked(place: string) {
    this.$emit('articleClicked', place);
  }

  blockShown(): void {
    this.$emit('blockShown');
  }
  videoPlayed() {
    this.$emit('videoPlayed');
  }
}
