










import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { SCROLL_ANALYTICS_BLOCK_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import type { IScrollAnalyticsBlockUseCase } from '@jtnews/shared/seedwork/frontend/application';
import {
  AnalyticsBlockPlace,
  type BlockPlaceRepositoryConfig,
  ScrollAnalyticsTarget,
} from '@jtnews/shared/seedwork/frontend/domain';
import { UiObserveVisibility } from '@jtnews/shared/ui';

@Component({
  name: 'ScrollAnalyticsBlock',
  components: {
    UiObserveVisibility,
  },
})
export default class ScrollAnalyticsBlock extends Vue {
  @Prop({
    required: true,
    type: Number,
  })
  target!: ScrollAnalyticsTarget;
  @Prop({
    default: () => ({
      defaultPlace: AnalyticsBlockPlace.Page,
      ignoreBreakpoints: true,
    }),
    type: Object as () => BlockPlaceRepositoryConfig,
  })
  blockPlaceConfig!: BlockPlaceRepositoryConfig;

  @Inject(SCROLL_ANALYTICS_BLOCK_USE_CASE_KEY)
  private readonly _useCase!: IScrollAnalyticsBlockUseCase;

  created() {
    this._useCase.setBlockPlace(this.blockPlaceConfig);
  }

  /**
   * этот хак нужен, чтобы отработал IntersectionObserver, но потом блок скрываем,
   * чтобы из-за использования компонента внутри контейнера с grid и grid-row-gap
   * не было лишнего grid-row-gap
   */
  updateBlockDisplay(value: 'none' | 'block') {
    if (this.$refs.container) {
      const targetElement = (this.$refs.container as UiObserveVisibility)
        .$el as HTMLDivElement;
      this.$nextTick(() => {
        targetElement.style.display = value;
      });
    }
  }

  processBlockShown() {
    this.updateBlockDisplay('none');
    this._useCase.processBlockShown(this.target);
  }
}
