





























































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/member-ordering, max-lines */

import { Component, Inject, Provide, Vue } from 'vue-property-decorator';

import type {
  IAllNewsButtonUseCase,
  INewsFeedSingleUseCase,
  IPictureDayBlockUseCase,
  ISingleCardBlockUseCase,
  ISuperCoverBlockUseCase,
  IWindowBlockUseCase,
} from '@jtnews/home/frontend/application';
import {
  HomePageStoreFacade,
  NEWS_FEED_SINGLE_USE_CASE_KEY,
  NewsFeedSingleUseCase,
  PICTURE_DAY_BLOCK_USE_CASE_KEY,
  PictureDayBlockUseCase,
  SINGLE_CARD_BLOCK_USE_CASE_KEY,
  SingleCardBlockUseCase,
  SUPER_COVER_BLOCK_USE_CASE_KEY,
  SuperCoverBlockUseCase,
  WINDOW_BLOCK_USE_CASE_KEY,
  WindowBlockUseCase,
  HOME_PAGE_STORE_FACADE_KEY,
  ALL_NEWS_BUTTON_USE_CASE_KEY,
  AllNewsButtonUseCase,
} from '@jtnews/home/frontend/application';
import {
  AllNewsButtonAnalyticsService,
  HomePageScrollAnalyticsService,
  NewsFeedSingleAnalyticsService,
  PictureDayAnalyticsService,
  SingleCardBlockAnalyticsService,
  SuperCoverBlockAnalyticsService,
  WindowBlockAnalyticsService,
} from '@jtnews/home/frontend/domain';
import type {
  IBookmarksUseCase,
  ICaptcha,
  ILogger,
  IReportageBlockUseCase,
  IScrollAnalyticsBlockUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import {
  CAPTCHA_KEY,
  LOGGER_KEY,
  REPORTAGE_BLOCK_USE_CASE_KEY,
  ReportageBlockUsecase,
  SCROLL_ANALYTICS_BLOCK_USE_CASE_KEY,
  ScrollAnalyticsBlockUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import type {
  IBlockPlaceRepository,
  IReachGoalAnalytics,
} from '@jtnews/shared/seedwork/frontend/domain';
import {
  AnalyticsBlockPlace,
  REACH_GOAL_ANALYTICS_KEY,
  ReportageBlockAnalyticsService,
  ScrollAnalyticsTarget,
} from '@jtnews/shared/seedwork/frontend/domain';
import {
  AdvBlock,
  InformerBlock,
  NewsFeedBlock,
  ScrollAnalyticsBlock,
  ThemesBlock,
  ReportageBlock,
  AdvInfinityFeed,
} from '@jtnews/shared/seedwork/frontend/features';
import type { IAppNewsClient } from '@jtnews/shared/seedwork/frontend/infrastructure';
import {
  BLOCK_TYPE_REPOSITORY_KEY,
  NEWS_CLIENT_KEY,
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import { grid, UiLayout, LazyBlock, LazyHydrate } from '@jtnews/shared/ui';
import { BOOKMARKS_USE_CASE_KEY, UserStoreFacade } from '@jtnews/users/frontend/api';

import { AllNewsButton } from '../all-news-button';
import { NewsFeedSingle } from '../news-feed-single';
import { PictureDayBlock } from '../picture-day-block';
import { SingleCardBlock } from '../single-card-block';
import { SuperCoverBlock } from '../super-cover-block';
import { WindowBlock } from '../window-block';

/* eslint-disable @typescript-eslint/naming-convention */
const ForumsBlock = () =>
  import(
    /* webpackChunkName: "forums-block" */ '@jtnews/shared/seedwork/frontend/features/forums-block'
  ).then((m) => m.ForumsBlock);

const LoveBlock = () =>
  import(
    /* webpackChunkName: "love-block" */ '@jtnews/shared/seedwork/frontend/features/love-block'
  ).then((m) => m.LoveBlock);

const OpinionsBlock = () =>
  import(
    /* webpackChunkName: "opinions-block" */ '@jtnews/shared/seedwork/frontend/features/opinions-block'
  ).then((m) => m.OpinionsBlock);

const RecommendationsBlock = () =>
  import(
    /* webpackChunkName: "recommendations-block" */ '@jtnews/shared/seedwork/frontend/features/recommendations-block'
  ).then((m) => m.RecommendationsBlock);

const TopArticlesBlock = () =>
  import(
    /* webpackChunkName: "top-articles-block" */ '@jtnews/shared/seedwork/frontend/features/top-articles-block'
  ).then((m) => m.TopArticlesBlock);

const DoBlock = () =>
  import(
    /* webpackChunkName: "do-block" */ '@jtnews/shared/seedwork/frontend/features/do-block'
  ).then((m) => m.DoBlock);

const AutoBlock = () =>
  import(
    /* webpackChunkName: "auto-block" */ '@jtnews/shared/seedwork/frontend/features/auto-block'
  ).then((m) => m.AutoBlock);

const FeedbackBlock = () =>
  import(
    /* webpackChunkName: "feedback-block" */ '@jtnews/shared/seedwork/frontend/features/feedback-block'
  ).then((m) => m.FeedbackBlock);

const PhotosBlock = () =>
  import(/* webpackChunkName: "photos-block" */ '../photos-block').then(
    (m) => m.PhotosBlock,
  );

const VideosBlock = () =>
  import(/* webpackChunkName: "videos-block" */ '../videos-block').then(
    (m) => m.VideosBlock,
  );

const OpinionBlock = () =>
  import(/* webpackChunkName: "opinion-block" */ '../opinion-block').then(
    (m) => m.OpinionBlock,
  );

const ActualThemeBlock = () =>
  import(/* webpackChunkName: "actual-theme-block" */ '../actual-theme-block').then(
    (m) => m.ActualThemeBlock,
  );
/* eslint-enable @typescript-eslint/naming-convention */

const LAZY_HYDRATION_START_INDEX = 4;

@Component({
  components: {
    LazyBlock,
    AllNewsButton,
    ActualThemeBlock,
    AdvBlock,
    AutoBlock,
    DoBlock,
    FeedbackBlock,
    ForumsBlock,
    InformerBlock,
    LazyHydrate,
    LoveBlock,
    NewsFeedBlock,
    NewsFeedSingle,
    OpinionBlock,
    OpinionsBlock,
    PhotosBlock,
    PictureDayBlock,
    SingleCardBlock,
    RecommendationsBlock,
    SuperCoverBlock,
    ThemesBlock,
    TopArticlesBlock,
    UiLayout,
    VideosBlock,
    ReportageBlock,
    WindowBlock,
    ScrollAnalyticsBlock,
    AdvInfinityFeed,
  },
  name: 'HomePage',
})
export default class HomePage extends Vue {
  @Inject(NEWS_CLIENT_KEY)
  newsClient!: IAppNewsClient;

  @Inject(LOGGER_KEY)
  logger!: ILogger;

  @Inject(REACH_GOAL_ANALYTICS_KEY)
  reachGoalAnalytics!: IReachGoalAnalytics;

  @Inject(BOOKMARKS_USE_CASE_KEY)
  bookmarkUseCase!: IBookmarksUseCase;

  @Inject(CAPTCHA_KEY)
  captcha!: ICaptcha;

  @Inject(BLOCK_TYPE_REPOSITORY_KEY)
  blockPlaceRepository!: IBlockPlaceRepository;

  @Provide(HOME_PAGE_STORE_FACADE_KEY)
  homePageStoreFacade = new HomePageStoreFacade(this.$pinia);

  userStoreFacade = new UserStoreFacade(this.$pinia);

  @Provide(SCROLL_ANALYTICS_BLOCK_USE_CASE_KEY)
  scrollAnalyticsUseCase: IScrollAnalyticsBlockUseCase =
    new ScrollAnalyticsBlockUseCase({
      deps: {
        logger: this.logger,
        analyticsService: new HomePageScrollAnalyticsService({
          pageName: this.homePageStoreFacade.pageName,
          hasSuperCover: this.homePageStoreFacade.hasSusperCover,
          deps: {
            blockPlaceRepository: this.blockPlaceRepository,
            analytics: this.reachGoalAnalytics,
          },
        }),
      },
    });

  @Provide(NEWS_FEED_SINGLE_USE_CASE_KEY)
  newsFeedSingleUseCase: INewsFeedSingleUseCase = new NewsFeedSingleUseCase({
    deps: {
      analyticsService: new NewsFeedSingleAnalyticsService({
        pageName: this.homePageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics,
        },
      }),
      bookmarkUseCase: this.bookmarkUseCase,
      logger: this.logger,
    },
  });

  @Provide(SINGLE_CARD_BLOCK_USE_CASE_KEY)
  singleCardBlockUseCase: ISingleCardBlockUseCase = new SingleCardBlockUseCase({
    deps: {
      analyticsService: new SingleCardBlockAnalyticsService({
        pageName: this.homePageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics,
        },
      }),
      bookmarkUseCase: this.bookmarkUseCase,
      logger: this.logger,
    },
  });

  @Provide(REPORTAGE_BLOCK_USE_CASE_KEY)
  reportageBlockUseCase: IReportageBlockUseCase = new ReportageBlockUsecase({
    deps: {
      analyticsService: new ReportageBlockAnalyticsService({
        pageName: this.homePageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics,
        },
      }),
      bookmarkUseCase: this.bookmarkUseCase,
      logger: this.logger,
    },
  });

  @Provide(WINDOW_BLOCK_USE_CASE_KEY)
  windowBlockUseCase: IWindowBlockUseCase = new WindowBlockUseCase({
    deps: {
      analyticsService: new WindowBlockAnalyticsService({
        pageName: this.homePageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics,
        },
      }),
      logger: this.logger,
      bookmarkUseCase: this.bookmarkUseCase,
    },
  });

  @Provide(ALL_NEWS_BUTTON_USE_CASE_KEY)
  allNewsButtonUseCase: IAllNewsButtonUseCase = new AllNewsButtonUseCase({
    deps: {
      logger: this.logger,
      analyticsService: new AllNewsButtonAnalyticsService({
        pageName: this.homePageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics,
        },
      }),
    },
  });

  @Provide(SUPER_COVER_BLOCK_USE_CASE_KEY)
  superCoverBlockUseCase: ISuperCoverBlockUseCase = new SuperCoverBlockUseCase({
    isMobile: this.homePageStoreFacade.isMobile,
    deps: {
      analyticsService: new SuperCoverBlockAnalyticsService({
        pageName: this.homePageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics,
        },
      }),
      logger: this.logger,
    },
  });

  @Provide(PICTURE_DAY_BLOCK_USE_CASE_KEY)
  pictureDayBlockUseCase: IPictureDayBlockUseCase = new PictureDayBlockUseCase({
    deps: {
      analyticsService: new PictureDayAnalyticsService({
        pageName: this.homePageStoreFacade.pageName,
        deps: {
          blockPlaceRepository: this.blockPlaceRepository,
          analytics: this.reachGoalAnalytics,
        },
      }),
      logger: this.logger,
    },
  });

  // homeRedesignWarningInform: IHomeRedesignWarningInform =
  //   new HomeRedesignWarningInform();
  //
  // homeRedesignRatingInform: IHomeRedesignRatingInform = new HomeRedesignRatingInform();
  //
  // homeRedesignWarningAnalyticsService: IHomeRedesignWarningAnalyticsService =
  //   new HomeRedesignOnboardingAnalyticsService({
  //     pageName: this.homePageStoreFacade.pageName,
  //     deps: {
  //       blockPlaceRepository: new BlockPlaceRepository(AnalyticsBlockPlace.Forms),
  //       analytics: this.reachGoalAnalytics,
  //     },
  //   });
  //
  // homeRedesignRatingAnalyticsService: IHomeRedesignRatingAnalyticsService =
  //   new HomeRedesignRatingAnalyticsService({
  //     pageName: this.homePageStoreFacade.pageName,
  //     deps: {
  //       blockPlaceRepository: new BlockPlaceRepository(AnalyticsBlockPlace.Forms),
  //       analytics: this.reachGoalAnalytics,
  //     },
  //   });
  //
  // homeRedesignWarningRepository: IHomeRedesignWarningRepository =
  //   new HomeRedesignWarningRepository();
  //
  // homeRedesignRatingRepository: IHomeRedesignRatingRepository =
  //   new HomeRedesignRatingRepository({
  //     regionId: this.homePageStoreFacade.regionId,
  //     deps: {
  //       client: this.newsClient,
  //       captcha: this.captcha,
  //     },
  //   });
  //
  // homeRedesignOnboardingService: IHomeRedesignOnboardingService =
  //   new HomeRedesignOnboardingService({
  //     deps: {
  //       warningInform: this.homeRedesignWarningInform,
  //       warningRepository: this.homeRedesignWarningRepository,
  //       ratingRepository: this.homeRedesignRatingRepository,
  //     },
  //   });
  //
  // @Provide(HOME_REDESIGN_ONBOARDING_USE_CASE_KEY)
  // homeRedesignOnboardingUseCase: IHomeRedesignOnboardingUseCase =
  //   new HomeRedesignOnboardingUseCase({
  //     deps: {
  //       onboardingService: this.homeRedesignOnboardingService,
  //       logger: this.logger,
  //     },
  //   });
  //
  // @Provide(HOME_REDESIGN_WARNING_USE_CASE_KEY)
  // homeRedesignWarningUseCase: IHomeRedesignWarningUseCase =
  //   new HomeRedesignWarningUseCase({
  //     deps: {
  //       onboardingService: this.homeRedesignOnboardingService,
  //       warningAnalyticsService: this.homeRedesignWarningAnalyticsService,
  //       warningInform: this.homeRedesignWarningInform,
  //       logger: this.logger,
  //     },
  //   });
  //
  // @Provide(HOME_REDESIGN_RATING_USE_CASE_KEY)
  // homeRedesignRatingUseCase: IHomeRedesignRatingUseCase = new HomeRedesignRatingUseCase(
  //   {
  //     deps: {
  //       onboardingService: this.homeRedesignOnboardingService,
  //       ratingAnalyticsService: this.homeRedesignRatingAnalyticsService,
  //       ratingInform: this.homeRedesignRatingInform,
  //       ratingRepository: this.homeRedesignRatingRepository,
  //       logger: this.logger,
  //     },
  //   },
  // );

  get adv() {
    return this.homePageStoreFacade.content.adv;
  }

  get isOpinionsBlockSlider() {
    return this.homePageStoreFacade.isMobile || this.homePageStoreFacade.isTablet;
  }

  get classes() {
    return grid;
  }

  get scrollPercent0() {
    return ScrollAnalyticsTarget.Percent0;
  }

  get informerBlockPlaceConfig() {
    return { defaultPlace: AnalyticsBlockPlace.Central, ignoreBreakpoints: true };
  }

  isLazyHydration(index: number) {
    return index >= LAZY_HYDRATION_START_INDEX;
  }
}
