


















































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { LinkTargetVM } from '@jtnews/shared/seedwork/frontend/application';

import { UiImage } from '../../ui-image';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type {
  TaxonomyClickEvent,
  NewsArchiveImage,
  NewsArchiveStatistic,
  NewsArchiveFormat,
  NewsArchiveRubric,
  NewsArchiveTheme,
} from './ui-news-archive.contract';
import { ArticleClickPlace } from './ui-news-archive.contract';

@Component({
  components: {
    UiNewsCardLabels,
    UiStatistic,
    UiObserveVisibility,
    UiImage,
  },
  name: 'UiNewsArchive',
})
export default class UiNewsArchive extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  title!: string;
  @Prop({
    type: String,
    default: '',
  })
  subtitle!: string;

  @Prop({
    type: String,
    required: true,
  })
  url!: string;

  @Prop({
    type: String as () => LinkTargetVM,
    required: true,
  })
  target!: LinkTargetVM;

  @Prop({
    type: Object as () => NewsArchiveImage | null,
    default: null,
  })
  image!: NewsArchiveImage | null;

  @Prop({
    type: Object as () => NewsArchiveStatistic,
    required: true,
  })
  statistic!: NewsArchiveStatistic;

  @Prop({
    type: Boolean,
    default: false,
  })
  isOnline: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasImageMark: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasVideoMark: boolean;

  @Prop({
    type: Array as () => NewsArchiveFormat[],
    default: () => [],
  })
  formats: NewsArchiveFormat[];

  @Prop({
    type: Array as () => NewsArchiveRubric[],
    default: () => [],
  })
  rubrics: NewsArchiveRubric[];

  @Prop({
    type: Array as () => NewsArchiveTheme[],
    default: () => [],
  })
  themes: NewsArchiveTheme[];

  @Prop({
    type: Boolean,
    default: false,
  })
  isBookmarked: boolean;

  get hasImage() {
    return guardUnspecified(this.image);
  }

  blockShown(): void {
    this.$emit('blockShown');
  }

  commentClicked(event: Event) {
    this.$emit('commentClicked', event);
  }

  bookmarkClicked(event: Event) {
    this.$emit('bookmarkClicked', event);
  }

  articleClicked(place: ArticleClickPlace) {
    this.$emit('articleClicked', place);
  }

  taxonomyClicked(event: TaxonomyClickEvent) {
    this.$emit('taxonomyClicked', event);
  }

  headerClick() {
    this.articleClicked(ArticleClickPlace.Header);
  }

  imageClick() {
    this.articleClicked(ArticleClickPlace.Image);
  }
}
