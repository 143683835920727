
















import { Component, Prop, Vue } from 'vue-property-decorator';

import type { PictureDayBlockDataItemVM } from '@jtnews/home/frontend/application';
import { UiScrollable } from '@jtnews/shared/ui';

@Component({
  components: { UiScrollable },
})
export default class PictureDayTabs extends Vue {
  @Prop({
    required: true,
    type: Array,
  })
  tabs!: PictureDayBlockDataItemVM[];

  @Prop({
    required: true,
    type: Object,
  })
  activeTab!: { title: string; index: number };

  onTabClicked(title: string, index: number) {
    this.$emit('onTabClicked', { title, index });
  }
}
