import type {
  IScrollAnalyticsService,
  ScrollAnalyticsTarget,
  BlockPlaceRepositoryConfig,
} from '@jtnews/shared/seedwork/frontend/domain';

import { BlockUseCase, IBlockUseCase } from '../block-usecase';
import type { BlockUseCaseConfig } from '../block-usecase';

export const SCROLL_ANALYTICS_BLOCK_USE_CASE_KEY = 'scroll_analytics_block_use_case_key';

export type ScrollAnalyticsBlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IScrollAnalyticsService;
  };
};

export interface IScrollAnalyticsBlockUseCase extends IBlockUseCase {
  processBlockShown(target: ScrollAnalyticsTarget): void;
}

export class ScrollAnalyticsBlockUseCase
  extends BlockUseCase
  implements IScrollAnalyticsBlockUseCase
{
  private readonly _analyticsService: IScrollAnalyticsService;

  constructor(config: ScrollAnalyticsBlockUseCaseConfig) {
    super(config);
    const {
      deps: { analyticsService },
    } = config;

    this._analyticsService = analyticsService;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processBlockShown(target: ScrollAnalyticsTarget): void {
    this._analyticsService.sendScrollEvent(target);
  }
}
