// eslint-disable-next-line
import './setup-composition-api';
import { guardUnspecified } from '@smh/utils/guards';
import { defineStore, Pinia } from 'pinia';

import { PageStoreFacade } from '@jtnews/shared/seedwork/frontend/application';

import type { ArticlesByRubricPage } from './articles-by-rubric-page.vm';

const useArticlesByRubricPageStore = defineStore('page', {
  state: () => ({} as ArticlesByRubricPage['page']),
  getters: {},
  actions: {},
});

export class ArticlesByRubricPageStoreFacade extends PageStoreFacade {
  private readonly _store: ReturnType<typeof useArticlesByRubricPageStore>;

  constructor(pinia: Pinia) {
    super(pinia);

    this._store = useArticlesByRubricPageStore(pinia);
  }

  public get grid() {
    return this._store.grid;
  }

  public get articlesBlock() {
    return this._store.content.articles;
  }

  public get fixedRecord() {
    return this._store.content.fixedRecord;
  }

  public get filter() {
    return this._store.content.filter;
  }

  public get articlesList() {
    const FIRST_ADV_ID = 10941;
    const SECOND_ADV_ID = 10951;
    const THIRD_ADV_ID = 10961;

    const advs: Record<number, number> = {
      [7]: FIRST_ADV_ID,
      [14]: SECOND_ADV_ID,
      [21]: THIRD_ADV_ID,
    };
    const { articles, fixedRecord } = this._store.content;
    if (guardUnspecified(articles)) {
      let { data } = articles;
      if (guardUnspecified(fixedRecord)) {
        data = data.filter((item) => item.id !== fixedRecord.data.id);
      }
      return data.flatMap((article, index) => {
        const advId = advs[index];
        const adv = guardUnspecified(this.content.adv) ? this.content.adv[advId] : null;
        return guardUnspecified(adv) ? [adv, article] : article;
      });
    }
    return [];
  }
}
