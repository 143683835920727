import type { IWindowBlockAnalyticsService } from '@jtnews/home/frontend/domain';
import {
  BlockUseCase,
  IBlockUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import type {
  BlockUseCaseConfig,
  IBookmarksUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';

import type { WindowItemVM } from './window-block.vm';

type AnalyticsData = {
  place: string;
  type: 'window' | 'feed';
};

type ArticleClick = {
  data: WindowItemVM;
  clickPlace: string;
  analyticsData: AnalyticsData;
};

export const WINDOW_BLOCK_USE_CASE_KEY = 'window_block_use_case_key';

export interface IWindowBlockUseCase extends IBlockUseCase {
  hasUserBookmark(articleId: number): boolean;
  processArticleClick(input: ArticleClick): void;
  processBlockShown(data: WindowItemVM, analyticsData: AnalyticsData): void;
  processBookmarkClick(articleId: number): void;
  processCommentClick(data: WindowItemVM, analyticsData: AnalyticsData): void;
  processMainNewsClick(id: number): void;
  processMainNewsShown(id: number): void;
  processTaxonomyClick(data: WindowItemVM, analyticsData: AnalyticsData): void;
  processVideoPlay(data: WindowItemVM, analyticsData: AnalyticsData): void;
}

export type WindowBlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IWindowBlockAnalyticsService;
    bookmarkUseCase: IBookmarksUseCase;
  };
};

export class WindowBlockUseCase extends BlockUseCase implements IWindowBlockUseCase {
  private readonly _analyticsService: IWindowBlockAnalyticsService;
  private readonly _bookmarkUseCase: IBookmarksUseCase;

  constructor(config: WindowBlockUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService, bookmarkUseCase },
    } = config;

    this._analyticsService = analyticsService;
    this._bookmarkUseCase = bookmarkUseCase;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public hasUserBookmark(articleId: number) {
    return this._bookmarkUseCase.hasUserBookmark(articleId);
  }

  public processMainNewsShown(id: number): void {
    this._analyticsService.sendEventMainNewsShown(id);
  }

  public processMainNewsClick(id: number): void {
    this._analyticsService.sendEventMainNewsClick(id);
  }

  public processTaxonomyClick(data: WindowItemVM, analyticsData: AnalyticsData): void {
    this._analyticsService.sendEventTaxonomyClick(data, analyticsData);
  }

  public processCommentClick(data: WindowItemVM, analyticsData: AnalyticsData): void {
    this._analyticsService.sendEventCommentClick(data, analyticsData);
  }

  public processArticleClick(input: ArticleClick): void {
    this._analyticsService.sendEventArticleClick(input);
  }

  public processBlockShown(data: WindowItemVM, analyticsData: AnalyticsData): void {
    this._analyticsService.sendEventBlockShown(data, analyticsData);
  }

  public processVideoPlay(data: WindowItemVM, analyticsData: AnalyticsData): void {
    this._analyticsService.sendEventVideoPlay(data, analyticsData);
  }

  public processBookmarkClick(articleId: number): void {
    void this._bookmarkUseCase.toggle({
      articleId,
      type: 'record',
    });
  }
}
