// eslint-disable-next-line
import './setup-composition-api';
import { guardUnspecified } from '@smh/utils/guards';
import { defineStore, Pinia } from 'pinia';

import { PageStoreFacade } from '@jtnews/shared/seedwork/frontend/application';

import type { HomePage } from './home-page.vm';
import type { PhotosNotLazyBlockVM, IPhotosBlockStore } from './photos-block';
import type { VideosNotLazyBlockVM, IVideosBlockStore } from './videos-block';

const useHomePageStore = defineStore('page', {
  state: () => ({} as HomePage['page']),
  getters: {},
  actions: {},
});

export const HOME_PAGE_STORE_FACADE_KEY = 'home_page_store_facade_key';

export class HomePageStoreFacade
  extends PageStoreFacade
  implements IPhotosBlockStore, IVideosBlockStore
{
  private readonly _store: ReturnType<typeof useHomePageStore>;

  constructor(pinia: Pinia) {
    super(pinia);

    this._store = useHomePageStore(pinia);
  }

  public get grid() {
    return this._store.grid;
  }

  public get superCoverBlock() {
    return this._store.content.superCover;
  }

  public get hasSusperCover() {
    return guardUnspecified(this.superCoverBlock);
  }

  public get pictureDayBlock() {
    return this._store.content.pictureDay;
  }

  public get videosBlock() {
    return this._store.content.videos;
  }

  public get photosBlock() {
    return this._store.content.photos;
  }

  public get opinionBlock() {
    return this._store.content.opinion;
  }

  public get actualThemeBlock() {
    return this._store.content.actualTheme;
  }

  public get windowBlock() {
    return this._store.content.window;
  }

  public get singleCardBlock() {
    return this._store.content.singleCard;
  }

  public get extraNews() {
    return (
      this.content.newsFeed?.data.map((item) => ({
        ...item,
        type: 'ui-news-m',
      })) ?? []
    );
  }

  updatePhotosBlock(block: PhotosNotLazyBlockVM) {
    this._store.content.photos = block;
  }

  updateVideosBlock(block: VideosNotLazyBlockVM | null = null) {
    this._store.content.videos = block;
  }
}
