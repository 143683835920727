































import { Component, Prop, Vue } from 'vue-property-decorator';

import type { PictureDayArticleVM } from '@jtnews/home/frontend/application';
import { UiImage } from '@jtnews/shared/ui';

@Component({
  name: 'PictureDayItem',
  components: { UiImage },
})
export default class PictureDayItem extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  item!: PictureDayArticleVM;

  onArticleClicked() {
    this.$emit('onArticleClicked', this.item);
  }
}
