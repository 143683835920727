import type {
  IReportageBlockAnalyticsService,
  BlockPlaceRepositoryConfig,
} from '@jtnews/shared/seedwork/frontend/domain';

import type { IBookmarksUseCase } from '../../users';
import { BlockUseCase, BlockUseCaseConfig, IBlockUseCase } from '../block-usecase';

import type { ReportageNotLazyBlockVM, ReportageVM } from './reportage-block.vm';

export const REPORTAGE_BLOCK_USE_CASE_KEY = 'reportage_block_use_case_key';

export interface IReportageBlockUseCase extends IBlockUseCase {
  hasUserBookmark(articleId: number): boolean;
  processArticleClick(articleId: number, place: string): void;
  processBlockShown(items: ReportageVM[]): void;
  processBookmarkClick(articleId: number): void;
  processCommentClick(articleId: number): void;
}

export interface IReportageBlockStore {
  updateReportageBlock: (block: ReportageNotLazyBlockVM) => void;
}

export type ReportageBlockUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IReportageBlockAnalyticsService;
    bookmarkUseCase: IBookmarksUseCase;
  };
};

export class ReportageBlockUsecase
  extends BlockUseCase
  implements IReportageBlockUseCase
{
  private readonly _analyticsService: IReportageBlockAnalyticsService;
  private readonly _bookmarkUseCase: IBookmarksUseCase;

  constructor(config: ReportageBlockUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService, bookmarkUseCase },
    } = config;

    this._analyticsService = analyticsService;
    this._bookmarkUseCase = bookmarkUseCase;
  }

  public hasUserBookmark(articleId: number) {
    return this._bookmarkUseCase.hasUserBookmark(articleId);
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processArticleClick(articleId: number, place: string): void {
    this._analyticsService.sendEventArticleClick(articleId, place);
  }

  public processBookmarkClick(articleId: number): void {
    this._analyticsService.sendEventBookmarkClick(articleId);
    void this._bookmarkUseCase.toggle({
      articleId,
      type: 'record',
    });
  }

  public processCommentClick(articleId: number): void {
    this._analyticsService.sendEventCommentClick(articleId);
  }

  public processBlockShown(items: ReportageVM[]): void {
    this._analyticsService.sendEventBlockShown(items);
  }
}
