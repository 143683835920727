



































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { PICTURE_DAY_BLOCK_USE_CASE_KEY } from '@jtnews/home/frontend/application';
import type {
  PictureDayArticleVM,
  IPictureDayBlockUseCase,
  PictureDayNotLazyBlockVM,
} from '@jtnews/home/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';
import {
  UiBaseBlock,
  UiButton,
  UiAdvLabel,
  UiObserveVisibility,
} from '@jtnews/shared/ui';

import PictureDayItem from './picture-day-item/picture-day-item.vue';
import PictureDayTabs from './picture-day-tabs/picture-day-tabs.vue';

@Component({
  name: 'PictureDayBlock',
  components: {
    UiAdvLabel,
    UiButton,
    UiBaseBlock,
    PictureDayItem,
    PictureDayTabs,
    UiObserveVisibility,
  },
})
export default class PictureDayBlock extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  block!: PictureDayNotLazyBlockVM;

  @Prop({
    required: true,
    type: Object as () => BlockPlaceRepositoryConfig,
  })
  blockPlaceConfig!: BlockPlaceRepositoryConfig;

  @Inject(PICTURE_DAY_BLOCK_USE_CASE_KEY)
  private readonly _useCase!: IPictureDayBlockUseCase;

  activeTab = { title: 'Главное', index: 0 };

  get tabs() {
    return this.block.data;
  }

  get items() {
    const tab = this.tabs[this.activeTab.index];
    return guardUnspecified(tab) ? tab.records : [];
  }

  get isFullHeight() {
    const PICTURE_ARTICLE_AMOUNT = 7;
    return this.items.length === PICTURE_ARTICLE_AMOUNT;
  }

  get isShowButton() {
    return this.activeTab.title === 'Видео';
  }

  created() {
    this._useCase.setBlockPlace(this.blockPlaceConfig);
  }

  processBlockView() {
    this._useCase.processBlockShown(this.activeTab.title);
  }

  processItemView(item: PictureDayArticleVM) {
    this._useCase.processItemShown(this.activeTab.title, item);
  }
  processAllNewsButtonClick() {
    this._useCase.processAllNewsButtonClick(this.activeTab.title);
  }

  onTabClicked(activeTab: { title: string; index: number }) {
    this.activeTab = activeTab;
    this._useCase.processTabClick(activeTab.title);
  }

  onArticleClicked(item: PictureDayArticleVM) {
    this._useCase.processArticleClick(this.activeTab.title, item);
  }
}
