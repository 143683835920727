import { guardMinLengthArray, guardZeroNumber } from '@smh/utils/guards';

import { ISuperCoverBlockAnalyticsService } from '@jtnews/home/frontend/domain';
import {
  BlockUseCase,
  BlockUseCaseConfig,
} from '@jtnews/shared/seedwork/frontend/application';

import type { SuperCoverBlockDataVM } from './super-cover-block.vm';

export const SUPER_COVER_BLOCK_USE_CASE_KEY = 'supercover_block_use_case_key';

export interface ISuperCoverBlockUseCase {
  isMobile: boolean;
  hasThemeArticles(data: SuperCoverBlockDataVM): boolean;
  hasMore(data: SuperCoverBlockDataVM): boolean;
  processBlockShown(mainId: number): void;
  processLinkClick(url: string): void;
  processMoreClick(themeId: number): void;
  processNewsClick(id: number): void;
  processThemeHeaderClick(id: number): void;
  processTitleClick(id: number): void;
}

export type SuperCoverBlockUseCaseConfig = BlockUseCaseConfig & {
  isMobile: boolean;
  deps: {
    analyticsService: ISuperCoverBlockAnalyticsService;
  };
};

export class SuperCoverBlockUseCase
  extends BlockUseCase
  implements ISuperCoverBlockUseCase
{
  private readonly _analyticsService: ISuperCoverBlockAnalyticsService;
  private readonly _isMobile: boolean;

  constructor(config: SuperCoverBlockUseCaseConfig) {
    super(config);

    const {
      isMobile,
      deps: { analyticsService },
    } = config;

    this._analyticsService = analyticsService;
    this._isMobile = isMobile;
  }

  public get isMobile() {
    return this._isMobile;
  }

  public hasThemeArticles(data: SuperCoverBlockDataVM): boolean {
    return guardZeroNumber(data.theme.articles.length);
  }

  public hasMore(data: SuperCoverBlockDataVM): boolean {
    const MAX_ARTICLES = 3;
    return guardMinLengthArray(data.theme.articles, MAX_ARTICLES);
  }

  public processThemeHeaderClick(id: number): void {
    this._analyticsService.sendEventThemeHeaderClick(id);
  }

  public processMoreClick(id: number): void {
    this._analyticsService.sendEventMoreClick(id);
  }

  public processNewsClick(id: number): void {
    this._analyticsService.sendEventNewsClick(id);
  }

  public processLinkClick(url: string): void {
    const id = this._getLinkId(url);
    this._analyticsService.sendEventLinkClick(id);
  }

  public processTitleClick(id: number): void {
    this._analyticsService.sendEventTitleClick(id);
  }

  public processBlockShown(id: number): void {
    this._analyticsService.sendEventBlockShown(id);
  }

  private _getLinkId(url: string): string {
    const id = url.match(/\d{8}/gi);
    return id ? id[0] : url;
  }
}
