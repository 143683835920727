// eslint-disable-next-line
import './setup-composition-api';
import { guardUnspecified } from '@smh/utils/guards';
import { defineStore, Pinia } from 'pinia';

import { PageStoreFacade } from '@jtnews/shared/seedwork/frontend/application';

import type { ArticlesByDatePage } from './articles-by-date-page.vm';

const useArticlesByDatePageStore = defineStore('page', {
  state: () => ({} as ArticlesByDatePage['page']),
  getters: {},
  actions: {},
});

export class ArticlesByDatePageStoreFacade extends PageStoreFacade {
  private readonly _store: ReturnType<typeof useArticlesByDatePageStore>;

  constructor(pinia: Pinia) {
    super(pinia);

    this._store = useArticlesByDatePageStore(pinia);
  }

  public get grid() {
    return this._store.grid;
  }

  public get articlesBlock() {
    return this._store.content.articles;
  }

  public get filter() {
    return this._store.content.filter;
  }

  public get articlesList() {
    const FIRST_ADV_ID = 10941;
    const SECOND_ADV_ID = 10951;
    const THIRD_ADV_ID = 10961;

    const advs: Record<number, number> = {
      [7]: FIRST_ADV_ID,
      [14]: SECOND_ADV_ID,
      [21]: THIRD_ADV_ID,
    };
    const { articles } = this._store.content;
    if (guardUnspecified(articles)) {
      return articles.data.flatMap((article, index) => {
        const advId = advs[index];
        const adv = guardUnspecified(this.content.adv) ? this.content.adv[advId] : null;
        return guardUnspecified(adv) ? [adv, article] : article;
      });
    }
    return [];
  }
}
