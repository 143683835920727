









































































import { guardUnspecified, guardEmptyString } from '@smh/utils/guards';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import type {
  IThemesBlockUseCase,
  MenuThemesBlockVM,
} from '@jtnews/shared/seedwork/frontend/application';
import { THEMES_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import { AnalyticsBlockPlace } from '@jtnews/shared/seedwork/frontend/domain';
import {
  UiAdvLabel,
  UiImage,
  UiObserveVisibility,
  UiScrollable,
  UiSlider,
} from '@jtnews/shared/ui';

const SCROLL_POSITION = 24;
const MAX_ADV_POPUP_WIDTH = 380;
const CONTENT_PADDING = 20;

@Component({
  name: 'ThemesBlock',
  components: { UiAdvLabel, UiImage, UiObserveVisibility, UiScrollable, UiSlider },
})
export default class ThemesBlock extends Vue {
  @Prop({
    required: true,
    type: Object as () => MenuThemesBlockVM,
  })
  block!: MenuThemesBlockVM;
  @Prop({
    default: false,
    type: Boolean,
  })
  isShowDesktopView!: boolean;

  @Inject(THEMES_USE_CASE_KEY) private readonly _useCase!: IThemesBlockUseCase;

  listMarginLeft = `-${SCROLL_POSITION}px`;
  listWidth = `calc(100% + ${SCROLL_POSITION}px)`;

  isShowAdvPopup = false;
  currentAdvCompanyName = '';
  currentAdvPopupToken = '';
  advPopupLeft = 0;
  advPopupArrowLeft = 0;

  get isMobile() {
    return this._useCase.isMobile;
  }

  get isMobileView() {
    return !this.isShowDesktopView && this.isMobile;
  }

  get items() {
    return this.block.data.map((item) => ({
      ...item,
      class: [this.$style.item, this.$style[`${item.status}Theme`]],
    }));
  }

  get listStyle() {
    if (!this.isMobileView) {
      return {};
    }

    return {
      marginLeft: this.listMarginLeft,
      width: this.listWidth,
    };
  }

  get advPopupStyle() {
    return `left: ${this.advPopupLeft}px;`;
  }

  get advPopupArrowStyle() {
    return `left: ${this.advPopupArrowLeft}px;`;
  }

  created() {
    this._useCase.setBlockPlace({
      defaultPlace: AnalyticsBlockPlace.Central,
      ignoreBreakpoints: true,
    });
  }

  mounted() {
    if (this.isMobileView) {
      this.scrollList();
    }
  }

  scrollList() {
    if (guardUnspecified(this.$refs.list)) {
      const list = this.$refs.list as Vue;

      this.listMarginLeft = 'initial';
      this.listWidth = '100%';
      list.$el.scrollBy(SCROLL_POSITION, 0);

      this.isShowAdvPopup = false;
    }
  }

  articleShown(url: string, index: number) {
    this._useCase.processBlockShown(url, index);
  }

  articleClicked(url: string, index: number) {
    this._useCase.processArticleClick(url, index);
  }

  advLabelClicked(event: Event, advCompanyName: string, advToken: string) {
    if (guardEmptyString(advCompanyName) && guardEmptyString(advToken)) {
      const targetInfo = (event.target as Element).getBoundingClientRect();
      const wrapperInfo = (this.$refs.wrapper as Element).getBoundingClientRect();

      this.isShowAdvPopup = true;
      this.currentAdvCompanyName = advCompanyName;
      this.currentAdvPopupToken = `Erid:&nbsp;${advToken}`;

      this.$nextTick(() => {
        const popupInfo = (this.$refs.advPopup as Element).getBoundingClientRect();

        this.advPopupArrowLeft =
          targetInfo?.left + targetInfo?.width / 2 - wrapperInfo?.left;

        if (window.innerWidth > MAX_ADV_POPUP_WIDTH) {
          this.advPopupLeft =
            targetInfo?.left +
            (targetInfo?.width - popupInfo?.width) / 2 -
            wrapperInfo?.left;

          if (this.advPopupLeft < 0) {
            this.advPopupLeft = 0;
          }

          if (this.advPopupLeft + popupInfo.width > window.innerWidth) {
            this.advPopupLeft = window.innerWidth - popupInfo.width - CONTENT_PADDING;
          }
        }
      });
    }
  }

  closeAdvPopup() {
    this.isShowAdvPopup = false;
  }
}
