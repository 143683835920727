import type {
  AnalyticsServiceConfig,
  IScrollAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import {
  AnalyticsService,
  ScrollAnalyticsTarget,
} from '@jtnews/shared/seedwork/frontend/domain';

const enum GoalName {
  Scrolling0 = 'viewOpenMain',
  Scrolling25 = 'viewScroll25Main',
  Scrolling50 = 'viewScroll50Main',
  Scrolling75 = 'viewScroll75Main',
  Scrolling100 = 'viewScroll100Main',
}

const enum Template {
  Standart = 'Стандартная',
  Supercover = 'Суперобложка',
}

export type ScrollAnalyticsServiceConfig = AnalyticsServiceConfig & {
  hasSuperCover: boolean;
};

export class HomePageScrollAnalyticsService
  extends AnalyticsService
  implements IScrollAnalyticsService
{
  private readonly _hasSuperCover: boolean;

  private readonly _fieldType = 'Скроллинг';

  private readonly _goalNamesMap = {
    [ScrollAnalyticsTarget.Percent0]: GoalName.Scrolling0,
    [ScrollAnalyticsTarget.Percent25]: GoalName.Scrolling25,
    [ScrollAnalyticsTarget.Percent50]: GoalName.Scrolling50,
    [ScrollAnalyticsTarget.Percent75]: GoalName.Scrolling75,
    [ScrollAnalyticsTarget.Percent100]: GoalName.Scrolling100,
  };

  constructor(config: ScrollAnalyticsServiceConfig) {
    super(config);

    const { hasSuperCover } = config;
    this._hasSuperCover = hasSuperCover;
  }

  public sendScrollEvent(target: ScrollAnalyticsTarget): void {
    const valueNameKey =
      target === ScrollAnalyticsTarget.Percent0 ? 'Открыл' : `${target}%`;
    const valueNameValue = this._hasSuperCover ? Template.Supercover : Template.Standart;

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._fieldType,
        goalName: this._goalNamesMap[target],
        valueName: {
          [valueNameKey]: valueNameValue,
        },
      },
    });
  }
}
