import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

type TaxonomyType = 'rubric' | 'format' | 'theme';

enum ActionType {
  View = 'Просмотр',
  Click = 'Переход на материал',
  Comment = 'Комментарии к материалу',
  Rubric = 'Рубрика',
  Format = 'Формат',
  Theme = 'Тема',
}

enum GoalName {
  View = 'viewArticle',
  ClickComment = 'viewAllComments',
  Click = 'clickArticle',
  ClickCategory = 'clickArticleCategory',
  ClickFormat = 'clickArticleFormat',
  ClickTheme = 'clickArticleTheme',
}

export type ArchiveArticleAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface IArchiveArticleAnalyticsService extends IAnalyticsService {
  sendEventArticleClick(articleId: number): void;
  sendEventBlockShown(articleId: number): void;
  sendEventCommentClick(articleId: number): void;
  sendEventTaxonomyClick(articleId: number, type: TaxonomyType): void;
}

export class ArchiveArticleAnalyticsService
  extends AnalyticsService
  implements IArchiveArticleAnalyticsService
{
  constructor(config: ArchiveArticleAnalyticsServiceConfig) {
    super(config);
  }

  sendEventArticleClick(articleId: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: ActionType.Click,
        valueName: `${articleId}`,
        goalName: GoalName.Click,
      },
    });
  }

  sendEventBlockShown(articleId: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: ActionType.View,
        valueName: `${articleId}`,
        goalName: GoalName.View,
      },
    });
  }

  sendEventCommentClick(articleId: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: ActionType.Comment,
        valueName: `${articleId}`,
        goalName: GoalName.ClickComment,
      },
    });
  }

  sendEventTaxonomyClick(articleId: number, type: TaxonomyType): void {
    const taxonomyActionType: Record<TaxonomyType, ActionType> = {
      rubric: ActionType.Rubric,
      format: ActionType.Format,
      theme: ActionType.Theme,
    };

    const taxonomyGoalName: Record<TaxonomyType, GoalName> = {
      rubric: GoalName.ClickCategory,
      format: GoalName.ClickFormat,
      theme: GoalName.ClickTheme,
    };
    const fieldType: string = taxonomyActionType[type];
    const goalName: string = taxonomyGoalName[type];

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType,
        valueName: `${articleId}`,
        goalName,
      },
    });
  }
}
