





































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import type { ArchiveArticleVM } from '@jtnews/records/frontend/application';
import {
  ARCHIVE_ARTICLES_USE_CASE_KEY,
  type IArchiveArticlesUseCase,
} from '@jtnews/records/frontend/application';
import { AnalyticsBlockPlace } from '@jtnews/shared/seedwork/frontend/domain';
import { AdvBlock } from '@jtnews/shared/seedwork/frontend/features';
import { UiNewsArchive, UiNewsL } from '@jtnews/shared/ui';

type TaxonomyType = 'rubric' | 'format' | 'theme';

@Component({
  name: 'ArchiveArticleItem',
  components: {
    UiNewsArchive,
    UiNewsL,
    AdvBlock,
  },
})
export default class ArchiveArticleItem extends Vue {
  @Prop({
    type: Object as () => ArchiveArticleVM,
    required: true,
  })
  item!: ArchiveArticleVM;

  @Prop({
    type: Boolean,
    required: true,
  })
  isMobile!: boolean;

  @Inject(ARCHIVE_ARTICLES_USE_CASE_KEY)
  private readonly _useCase!: IArchiveArticlesUseCase;

  get isBookmarked() {
    return this._useCase.hasUserBookmark(this.item.id);
  }

  created() {
    this._useCase.setBlockPlace({
      defaultPlace: AnalyticsBlockPlace.Content,
      ignoreBreakpoints: true,
    });
  }

  processBlockShown(): void {
    this._useCase.processBlockShown(this.item);
  }

  processCommentClick(): void {
    this._useCase.processCommentClick(this.item);
  }

  processArticleClick(clickPlace: string): void {
    this._useCase.processArticleClick({
      data: this.item,
      clickPlace,
    });
  }

  processBookmarkClick(): void {
    this._useCase.processBookmarkClick(this.item.id);
  }

  processTaxonomyClick({ taxonomyType }: { taxonomyType: TaxonomyType }): void {
    this._useCase.processTaxonomyClick(this.item.id, taxonomyType);
  }
}
