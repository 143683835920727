import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

type Article = {
  id: number;
  isCommercial: boolean;
};

type ArticleClick = {
  data: Article;
  clickPlace: string;
  place: string;
};

enum ActionType {
  View = 'Просмотр',
  Click = 'Переход на материал',
  Comment = 'Переход на комментарии',
}

enum GoalName {
  View = 'viewNewsFeed',
  Click = 'clickNewsFeed',
}

export type NewsFeedSingleAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface INewsFeedSingleAnalyticsService extends IAnalyticsService {
  sendEventArticleClick(input: ArticleClick): void;
  sendEventBlockShown(data: Article, place: string): void;
  sendEventCommentClick(data: Article, place: string): void;
}

export class NewsFeedSingleAnalyticsService
  extends AnalyticsService
  implements INewsFeedSingleAnalyticsService
{
  private readonly _blockName = 'Лента новостей';

  constructor(config: NewsFeedSingleAnalyticsServiceConfig) {
    super(config);
  }

  public sendEventArticleClick(input: ArticleClick): void {
    const { data, clickPlace, place } = input;
    const articleType = data.isCommercial ? 'Рекламный' : 'Редакционный';
    const valueName = {
      [place]: {
        [clickPlace]: {
          [ActionType.Click]: {
            [articleType]: `${data.id}`,
          },
        },
      },
    };

    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.Click,
        pageName: this.pageName,
        valueName,
      },
    });
  }

  public sendEventBlockShown(data: Article, place: string): void {
    const articleType = data.isCommercial ? 'Рекламный' : 'Редакционный';
    const valueName = {
      [place]: {
        [ActionType.View]: {
          [articleType]: `${data.id}`,
        },
      },
    };

    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.View,
        pageName: this.pageName,
        valueName,
      },
    });
  }

  public sendEventCommentClick(data: Article, place: string): void {
    const valueName = {
      [place]: {
        [ActionType.Comment]: `${data.id}`,
      },
    };

    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.Click,
        pageName: this.pageName,
        valueName,
      },
    });
  }
}
