



































































































































































































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import type {
  WindowItemVM,
  IWindowBlockUseCase,
} from '@jtnews/home/frontend/application';
import { WINDOW_BLOCK_USE_CASE_KEY } from '@jtnews/home/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';
import {
  UiNewsMain,
  UiNewsVertical,
  UiNewsM,
  UiNewsHorizontal,
  UiNewsL,
  UiNewsS,
  UiNewsVery,
  UiNewsPhoto,
  UiLabel,
} from '@jtnews/shared/ui';

type Meta = {
  place: string;
  fullHeight?: boolean;
  type: 'window' | 'feed';
};

@Component({
  components: {
    UiNewsMain,
    UiNewsVertical,
    UiNewsL,
    UiNewsM,
    UiNewsHorizontal,
    UiNewsS,
    UiNewsVery,
    UiNewsPhoto,
    UiLabel,
  },
})
export default class WindowBlock extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  primaryItem!: WindowItemVM;

  @Prop({
    required: true,
    type: Object as () => Meta,
  })
  meta!: Meta;

  @Prop({
    required: true,
    type: Object as () => BlockPlaceRepositoryConfig,
  })
  blockPlaceConfig!: BlockPlaceRepositoryConfig;

  @Prop({
    type: Boolean,
    default: false,
  })
  isMobile!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasSuperCover!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isTablet!: boolean;

  @Inject(WINDOW_BLOCK_USE_CASE_KEY) private readonly _useCase!: IWindowBlockUseCase;

  get isMainNews() {
    return this.primaryItem.type === 'ui-news-main';
  }

  get isBookmarked() {
    return this._useCase.hasUserBookmark(this.primaryItem.id);
  }

  created() {
    this._useCase.setBlockPlace(this.blockPlaceConfig);
  }

  processBlockShown() {
    if (this.isMainNews) {
      this.processMainNewsShow();
      return;
    }
    this.processWindowShown();
  }

  processMainNewsShow() {
    this._useCase.processMainNewsShown(this.primaryItem.id);
  }

  processPlayVideo() {
    this._useCase.processVideoPlay(this.primaryItem, this.meta);
  }

  processMainNewsClick() {
    this._useCase.processMainNewsClick(this.primaryItem.id);
  }

  processWindowShown() {
    this._useCase.processBlockShown(this.primaryItem, this.meta);
  }

  processCommentClick() {
    this._useCase.processCommentClick(this.primaryItem, this.meta);
  }

  processArticleClick(clickPlace: string) {
    this._useCase.processArticleClick({
      data: this.primaryItem,
      clickPlace,
      analyticsData: this.meta,
    });
  }

  processTaxonomyClick() {
    this._useCase.processTaxonomyClick(this.primaryItem, this.meta);
  }

  processBookmarkClick() {
    this._useCase.processBookmarkClick(this.primaryItem.id);
  }
}
