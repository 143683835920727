


























































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { NEWS_FEED_BLOCK_USE_CASE_KEY } from '@jtnews/shared/seedwork/frontend/application';
import type {
  NewsFeedNotLazyBlockVM,
  INewsFeedBlockUseCase,
  PageAdvVM,
} from '@jtnews/shared/seedwork/frontend/application';
import type {
  INewsFeedGridBlock,
  BlockPlaceRepositoryConfig,
} from '@jtnews/shared/seedwork/frontend/domain';
import { UiBaseBlock, UiButton } from '@jtnews/shared/ui';

import { AdvBlock } from '../adv-block';

import NewsFeedBlockItem from './news-feed-block-item/news-feed-block-item.component.vue';

@Component({
  name: 'NewsFeedBlock',
  components: {
    UiButton,
    NewsFeedBlockItem,
    UiBaseBlock,
    AdvBlock,
  },
})
export default class NewsFeedBlock extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  block!: NewsFeedNotLazyBlockVM;

  @Prop({
    required: true,
    type: Object as () => PageAdvVM,
  })
  adv!: PageAdvVM;

  @Prop({
    required: true,
    type: Object as () => INewsFeedGridBlock,
  })
  grid!: INewsFeedGridBlock;

  @Prop({
    required: true,
    type: Object as () => BlockPlaceRepositoryConfig,
  })
  blockPlaceConfig!: BlockPlaceRepositoryConfig;

  @Inject(NEWS_FEED_BLOCK_USE_CASE_KEY)
  private readonly _useCase!: INewsFeedBlockUseCase;

  get params() {
    return this.block.params;
  }

  get items() {
    return this.block.data;
  }

  get lastIndex(): number {
    return this.items.length - 1;
  }

  created() {
    this._useCase.setBlockPlace(this.blockPlaceConfig);
  }

  processArticleView(data: {
    currentPlace: number;
    id: number;
    isCommercial: boolean;
  }) {
    this._useCase.processNewsView(data);
  }

  processArticleClick(data: {
    currentPlace: number;
    id: number;
    isCommercial: boolean;
  }) {
    this._useCase.processArticleClick(data);
  }

  processAllNewsClick() {
    this._useCase.processAllNewsClick();
  }
}
