import { IAllNewsButtonAnalyticsService } from '@jtnews/home/frontend/domain';
import { BlockUseCase } from '@jtnews/shared/seedwork/frontend/application';
import type {
  BlockUseCaseConfig,
  IBlockUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';

export const ALL_NEWS_BUTTON_USE_CASE_KEY = 'all_news_button_use_case_key';

export interface IAllNewsButtonUseCase extends IBlockUseCase {
  processButtonClick(): void;
  processButtonView(): void;
}

export type AllNewsButtonUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IAllNewsButtonAnalyticsService;
  };
};

export class AllNewsButtonUseCase extends BlockUseCase implements IAllNewsButtonUseCase {
  private readonly _analyticsService: IAllNewsButtonAnalyticsService;

  constructor(config: AllNewsButtonUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService },
    } = config;

    this._analyticsService = analyticsService;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticsService.setBlockPlace(blockPlaceConfig);
  }

  public processButtonClick(): void {
    this._analyticsService.sendEventClick();
  }
  public processButtonView(): void {
    this._analyticsService.sendEventView();
  }
}
