






















































































import { Component, Prop, Vue, Inject } from 'vue-property-decorator';

import type {
  SuperCoverNotLazyBlockVM,
  ISuperCoverBlockUseCase,
} from '@jtnews/home/frontend/application';
import { SUPER_COVER_BLOCK_USE_CASE_KEY } from '@jtnews/home/frontend/application';
import { UiImage, UiButton, UiLabel, UiObserveVisibility } from '@jtnews/shared/ui';

const MOBILE_COVER_HEIGHT = 300;

@Component({
  name: 'SuperCoverBlock',
  components: {
    UiImage,
    UiButton,
    UiLabel,
    UiObserveVisibility,
  },
})
export default class SuperCoverBlock extends Vue {
  @Prop({
    required: true,
    type: Object as () => SuperCoverNotLazyBlockVM,
  })
  block!: SuperCoverNotLazyBlockVM;

  @Inject(SUPER_COVER_BLOCK_USE_CASE_KEY)
  private readonly _useCase!: ISuperCoverBlockUseCase;

  get isMobile() {
    return this._useCase.isMobile;
  }

  get imageHeight() {
    if (this.isMobile) {
      return MOBILE_COVER_HEIGHT;
    }
  }

  get params() {
    return this.block.params;
  }

  get content() {
    return this.block.data;
  }

  get hasThemeArticles() {
    return this._useCase.hasThemeArticles(this.content);
  }

  get hasMore() {
    return this._useCase.hasMore(this.content);
  }

  processBlockShown() {
    if (this.block.data) {
      this._useCase.processBlockShown(this.block.data.main.id);
    }
  }

  onMoreClicked() {
    this._useCase.processMoreClick(this.block.data.theme.id);
  }

  onMainArticleClicked() {
    this._useCase.processTitleClick(this.block.data.main.id);
  }

  onLinkClicked(link: string) {
    this._useCase.processLinkClick(link);
  }

  onThemeClicked() {
    this._useCase.processThemeHeaderClick(this.block.data.theme.id);
  }

  onArticleClicked(articleId: number) {
    this._useCase.processNewsClick(articleId);
  }
}
