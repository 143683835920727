

































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiButton } from '../ui-button';

import type { PaginationPage, PaginationPages } from './ui-pagination.contract';

const PAGE_COUNT = 3;

@Component({
  components: {
    UiButton,
  },
  name: 'UiPagination',
})
export default class UiPagination extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  value!: number;

  @Prop({
    type: Number,
    required: true,
  })
  totalPages!: number;

  @Prop({
    type: Number,
    default: 3,
  })
  maxSize!: number;

  @Prop({
    type: Boolean,
    required: true,
  })
  isMobile!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  isShowMoreBtn!: boolean;

  @Prop({
    type: String,
    default: '/',
  })
  url!: string;

  @Prop({
    type: String,
    default: 'page',
  })
  query!: string;

  currentPage = this.value;

  get pages(): PaginationPages {
    const pages = [] as PaginationPages;

    if (this.currentPage <= 0 || this.currentPage > this.totalPages) {
      return pages;
    }

    // Default page limits
    let startPage = 1;
    let endPage = this.totalPages;
    const isMaxSized = this.isDefined(this.maxSize) && this.maxSize;

    // recompute if maxSize
    if (isMaxSized < this.totalPages) {
      // Current page is displayed in the middle of the visible ones
      startPage = Math.max(this.currentPage - Math.floor(this.maxSize / 2), 1);
      endPage = startPage + this.maxSize - 1;

      // Adjust if limit is exceeded
      if (endPage > this.totalPages) {
        endPage = this.totalPages;
        startPage = endPage - this.maxSize + 1;
      }
    }

    // Add page number links
    for (let number = startPage; number <= endPage; number += 1) {
      const page = this.makePage({
        number,
        text: `${number}`,
        isActive: number === this.currentPage,
      });
      pages.push(page);
    }

    // Add links to move between page sets
    if (isMaxSized < this.totalPages && this.maxSize > 0) {
      if (startPage > 1) {
        if (startPage > PAGE_COUNT) {
          // need ellipsis for all options unless range is too close to beginning
          const previousPageSet = this.makePage({
            number: startPage - 1,
            text: '...',
            isActive: false,
          });
          pages.unshift(previousPageSet);
        }

        if (startPage === PAGE_COUNT) {
          // need to replace ellipsis when the buttons would be sequential
          const secondPageLink = this.makePage({
            number: 2,
            text: '2',
            isActive: false,
          });
          pages.unshift(secondPageLink);
        }

        // add the first page
        const firstPageLink = this.makePage({ number: 1, text: '1', isActive: false });
        pages.unshift(firstPageLink);
      }

      if (endPage < this.totalPages) {
        if (endPage < this.totalPages - 2) {
          // need ellipsis for all options unless range is too close to end
          const nextPageSet = this.makePage({
            number: endPage + 1,
            text: '...',
            isActive: false,
          });
          pages.push(nextPageSet);
        }
        if (endPage === this.totalPages - 2) {
          // need to replace ellipsis when the buttons would be sequential
          const secondToLastPageLink = this.makePage({
            number: this.totalPages - 1,
            text: `${this.totalPages - 1}`,
            isActive: false,
          });
          pages.push(secondToLastPageLink);
        }
        // add the last page
        const lastPageLink = this.makePage({
          number: this.totalPages,
          text: `${this.totalPages}`,
          isActive: false,
        });
        pages.push(lastPageLink);
      }
    }

    // Emit the new data to the parent.
    // this.input();

    return pages;
  }

  get isDisabledLeftBtn(): boolean {
    return this.currentPage === 1;
  }

  get isDisabledRightBtn(): boolean {
    return this.currentPage === this.totalPages;
  }

  get leftButtonUrl(): string | null {
    return this.getUrl(!this.isDisabledLeftBtn, this.currentPage - 1);
  }

  get rightButtonUrl(): string | null {
    return this.getUrl(!this.isDisabledRightBtn, this.currentPage + 1);
  }

  @Emit('change')
  change(page: number): number {
    return page;
  }

  @Emit('showMore')
  showMore(): void {}

  isDefined(value: number): boolean {
    return typeof value !== 'undefined';
  }
  getUrl(hasUrl: boolean, number: number) {
    return hasUrl ? `${this.url}?${this.query}=${number}` : null;
  }
  makePage(input: { number: number; text: string; isActive: boolean }): PaginationPage {
    const { number, text, isActive } = input;
    return {
      number,
      text,
      isActive,
      url: this.getUrl(true, number),
    };
  }

  selectPage(page: number) {
    this.change(page);
  }
}
