

























































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { NEWS_FEED_SINGLE_USE_CASE_KEY } from '@jtnews/home/frontend/application';
import type { INewsFeedSingleUseCase } from '@jtnews/home/frontend/application';
import type { NewsFeedWithImageVM } from '@jtnews/shared/seedwork/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';
import {
  UiObserveVisibility,
  UiNewsM,
  UiNewsS,
  UiNewsMain,
  UiNewsL,
  UiNewsDouble,
} from '@jtnews/shared/ui';

type Meta = {
  place: string;
  type: 'feed';
};

@Component({
  name: 'NewsFeedSingle',
  components: {
    UiObserveVisibility,
    UiNewsM,
    UiNewsL,
    UiNewsS,
    UiNewsMain,
    UiNewsDouble,
  },
})
export default class NewsFeedSingle extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  primaryItem!: NewsFeedWithImageVM;

  @Prop({
    required: true,
    type: Object as () => Meta,
  })
  meta!: Meta;

  @Prop({
    required: true,
    type: Object as () => BlockPlaceRepositoryConfig,
  })
  blockPlaceConfig!: BlockPlaceRepositoryConfig;

  @Prop({
    type: Boolean,
    default: false,
  })
  isMobile!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isTablet!: boolean;

  @Inject(NEWS_FEED_SINGLE_USE_CASE_KEY)
  private readonly _useCase!: INewsFeedSingleUseCase;

  get isBookmarked() {
    return this._useCase.hasUserBookmark(this.primaryItem.id);
  }

  get doublePrimaryItem() {
    return {
      ...this.primaryItem,
      isBookmarked: this.isBookmarked,
    };
  }

  created() {
    this._useCase.setBlockPlace(this.blockPlaceConfig);
  }

  processBlockShown() {
    this._useCase.processBlockShown(this.primaryItem, this.meta.place);
  }

  processCommentClick() {
    this._useCase.processCommentClick(this.primaryItem, this.meta.place);
  }

  processArticleClick(clickPlace: string) {
    this._useCase.processArticleClick({
      data: this.primaryItem,
      clickPlace,
      place: this.meta.place,
    });
  }

  processBookmarkClick() {
    this._useCase.processBookmarkClick(this.primaryItem.id);
  }
}
