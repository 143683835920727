









































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { LinkTargetVM } from '@jtnews/shared/seedwork/frontend/application';

import { UiImage } from '../../ui-image';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';
import { UiViqeoVideo } from '../../ui-viqeo-video';

import type {
  NewsLImage,
  NewsFeedTaxonomy,
  NewsLStatistic,
  NewsLVideo,
} from './ui-news-l.contract';

@Component({
  components: {
    UiViqeoVideo,
    UiStatistic,
    UiObserveVisibility,
    UiImage,
    UiNewsCardLabels,
  },
  name: 'UiNewsL',
})
export default class UiNewsL extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  url!: string;

  @Prop({
    type: String as () => LinkTargetVM,
    required: true,
  })
  target!: LinkTargetVM;

  @Prop({
    type: Object as () => NewsLImage | null,
    default: null,
  })
  image!: NewsLImage | null;

  @Prop({
    type: Object as () => NewsLVideo | null,
    default: null,
  })
  video!: NewsLVideo;

  @Prop({
    type: Object as () => NewsLStatistic,
    required: true,
  })
  statistic!: NewsLStatistic;

  @Prop({
    type: Boolean,
    default: false,
  })
  isBookmarked: boolean;

  @Prop({
    type: Array as () => NewsFeedTaxonomy[],
    default: () => [],
  })
  rubrics: NewsFeedTaxonomy[];

  @Prop({
    type: Array as () => NewsFeedTaxonomy[],
    default: () => [],
  })
  formats: NewsFeedTaxonomy[];

  @Prop({
    type: Array as () => NewsFeedTaxonomy[],
    default: () => [],
  })
  themes: NewsFeedTaxonomy[];

  get hasVideo() {
    return guardUnspecified(this.video);
  }

  blockShown() {
    this.$emit('blockShown');
  }

  commentClicked(event: Event) {
    this.$emit('commentClicked', event);
  }

  bookmarkClicked(event: Event) {
    this.$emit('bookmarkClicked', event);
  }

  videoPlayed() {
    this.$emit('videoPlayed');
  }

  articleClicked(place: string) {
    this.$emit('articleClicked', place);
  }
}
