import { AnalyticsService } from '../analytics';
import type { AnalyticsServiceConfig, IAnalyticsService } from '../analytics';

type Article = {
  id: number;
  place: string;
};

enum ActionType {
  View = 'Просмотр',
  Click = 'Клик',
}

enum GoalName {
  View = 'viewWeekend',
  Click = 'clickWeekend',
}

export interface IReportageBlockAnalyticsService extends IAnalyticsService {
  sendEventArticleClick(articleId: number, place: string): void;
  sendEventBlockShown(items: Article[]): void;
  sendEventBookmarkClick(articleId: number): void;
  sendEventCommentClick(articleId: number): void;
}

export type ReportageBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export class ReportageBlockAnalyticsService
  extends AnalyticsService
  implements IReportageBlockAnalyticsService
{
  private readonly _blockName = 'Уикенд';

  constructor(config: ReportageBlockAnalyticsServiceConfig) {
    super(config);
  }
  sendEventArticleClick(articleId: number, place: string): void {
    const valueName = {
      [ActionType.Click]: {
        [place]: `${articleId}`,
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.Click,
        valueName,
      },
    });
  }

  sendEventBlockShown(items: Article[]): void {
    const ids = items.map((item) => item.id).join(', ') ?? '';
    const places = items.map((item) => item.place).join(', ') ?? '';
    const valueName = {
      [ActionType.View]: {
        [places]: ids,
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.View,
        valueName,
      },
    });
  }

  sendEventBookmarkClick(articleId: number): void {
    const valueName = {
      [ActionType.Click]: {
        ['Закладки']: `${articleId}`,
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.Click,
        valueName,
      },
    });
  }

  sendEventCommentClick(articleId: number): void {
    const valueName = {
      [ActionType.Click]: {
        ['Комментарий']: `${articleId}`,
      },
    };

    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.Click,
        valueName,
      },
    });
  }
}
