


























import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { UiSpin } from '../ui-spin';

import type { Video } from './ui-viqeo-video.contract';

const EVENT_NAMES: viqeo.ViqeoPlayerEvents[] = [
  'Player:started',
  'Player:ended',
  'Player:replayed',
  'Player:advEnded',
  'Player:paused',
  'Player:played',
  'Player:videoLoaded',
];

@Component({
  name: 'UiViqeoVideo',
  components: { UiSpin },
})
export default class UiViqeoVideo extends Vue {
  @Prop({
    type: Object as () => Video,
    required: true,
  })
  video: Video;

  isLoading = true;
  hasFirstPlay = false;
  callbacksLoaded = false;

  get src() {
    return guardUnspecified(this.video)
      ? `https://cdn.viqeo.tv/embed/?vid=${this.video.videoId}`
      : '';
  }

  mounted() {
    this.init();
  }

  init() {
    if (window.VIQEO) {
      void this.start();
    } else if (typeof window.onViqeoLoad === 'undefined') {
      window.onViqeoLoad = [this.start.bind(this)];
    } else if (typeof window.onViqeoLoad === 'function') {
      window.onViqeoLoad = [window.onViqeoLoad, this.start.bind(this)];
    } else {
      window.onViqeoLoad.push(this.start.bind(this));
    }
  }
  async start() {
    const { subscribeTracking } = await import('./viqeo-player');
    if (!this.callbacksLoaded) {
      this.callbacksLoaded = true;
      EVENT_NAMES.forEach((name) => {
        subscribeTracking((event) => {
          this.viqeoEventsHandle(event);
        }, name);
      });
    }
  }
  viqeoEventsHandle(event: viqeo.ViqeoEventPayload) {
    if (event.eventName === 'Player:videoLoaded') {
      this.isLoading = false;
    }
    if (event.eventName === 'Player:started') {
      this.isLoading = false;
      if (!this.hasFirstPlay) {
        this.hasFirstPlay = true;
        this.$emit('videoPlayed');
      }
    }
  }
}
