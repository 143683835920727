import type { Image } from '../../ui-image';
import type { Statistic } from '../../ui-statistic';

export type TaxonomyClickEvent = {
  event: Event;
  taxonomyType: string;
  id: number;
};

export enum ArticleClickPlace {
  Header = 'Header',
  Image = 'Image',
}

export type NewsArchiveImage = Image | null;

export type NewsArchiveStatistic = Statistic;

export type NewsArchiveFormat = {
  id: number;
  title: string;
  url: string;
};

export type NewsArchiveRubric = {
  id: number;
  title: string;
  url: string;
};

export type NewsArchiveTheme = {
  id: number;
  title: string;
  url: string;
};
