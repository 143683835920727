import { IAnalyticsService } from '../analytics';

export const enum ScrollAnalyticsTarget {
  Percent0 = 0,
  Percent25 = 25,
  Percent50 = 50,
  Percent75 = 75,
  Percent100 = 100,
}

export interface IScrollAnalyticsService extends IAnalyticsService {
  sendScrollEvent: (target: ScrollAnalyticsTarget) => void;
}
