






















import { Component, Prop, Vue } from 'vue-property-decorator';

import type { ArchiveArticleVM } from '@jtnews/records/frontend/application';
import type { AdvVM } from '@jtnews/shared/seedwork/frontend/application';
import { AdvBlock } from '@jtnews/shared/seedwork/frontend/features';
import { UiNewsArchive, UiNewsL } from '@jtnews/shared/ui';

import ArchiveArticleItem from './archive-article-item/archive-article-item.vue';

@Component({
  name: 'ArchiveArticles',
  components: {
    ArchiveArticleItem,
    UiNewsArchive,
    UiNewsL,
    AdvBlock,
  },
})
export default class ArchiveArticles extends Vue {
  @Prop({
    type: Array as () => (ArchiveArticleVM | AdvVM)[],
    required: true,
  })
  articles!: (ArchiveArticleVM | AdvVM)[];
  @Prop({
    type: Boolean,
    required: true,
  })
  isMobile!: boolean;
}
