import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

enum ActionType {
  Click = 'Клик',
  View = 'Просмотр',
}

enum GoalName {
  Click = 'clickQuote',
  View = 'viewQuote',
}

export type SingleCardBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface ISingleCardBlockAnalyticsService extends IAnalyticsService {
  sendEventBlockClick(id: number): void;
  sendEventBlockShown(id: number): void;
}

export class SingleCardBlockAnalyticsService
  extends AnalyticsService
  implements ISingleCardBlockAnalyticsService
{
  private readonly _blockName = 'Цитата';

  constructor(config: SingleCardBlockAnalyticsServiceConfig) {
    super(config);
  }

  sendEventBlockClick(id: number): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.Click,
        pageName: this.pageName,
        valueName: { [ActionType.Click]: `${id}` },
      },
    });
  }

  sendEventBlockShown(id: number): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.View,
        pageName: this.pageName,
        valueName: { [ActionType.View]: `${id}` },
      },
    });
  }
}
