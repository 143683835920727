import type { AnalyticsServiceConfig } from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

enum ActionType {
  View = 'Просмотр',
  Click = 'Клик',
  ClickBottom = 'Клик Точки входа',
  ClickNews = 'Клик Блок сюжета',
  ClickOne = 'Клик Заголовок',
  ClickMore = 'Клик Показать еще',
  ClickMoreTop = 'Заголовок блока сюжета',
}

enum GoalName {
  View = 'viewSupermain',
  ClickOne = 'clickSupermainOne',
  ClickBottom = 'clickSupermainBottom',
  ClickNews = 'clickSupermainNews',
  ClickMore = 'clickSupermainMore',
  ClickMoreTop = 'clickSupermainMoreTop',
}

export type SuperCoverBlockAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface ISuperCoverBlockAnalyticsService {
  sendEventBlockShown(mainId: number): void;
  sendEventLinkClick(id: string): void;
  sendEventMoreClick(themeId: number): void;
  sendEventNewsClick(id: number): void;
  sendEventThemeHeaderClick(id: number): void;
  sendEventTitleClick(id: number): void;
}

export class SuperCoverBlockAnalyticsService
  extends AnalyticsService
  implements ISuperCoverBlockAnalyticsService
{
  private readonly _blockName = 'Суперобложка';

  constructor(config: SuperCoverBlockAnalyticsServiceConfig) {
    super(config);
  }

  sendEventBlockShown(mainId: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName: { [ActionType.View]: `${mainId}` },
        goalName: GoalName.View,
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this._blockName,
        goalName: 'ViewShowcase',
        actionType: ActionType.View,
        prop1: `${mainId}`,
      },
    });
  }

  sendEventLinkClick(id: string): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName: { [ActionType.ClickBottom]: id },
        goalName: GoalName.ClickBottom,
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this._blockName,
        goalName: 'ClickShowcase',
        actionType: 'Клик',
        prop1: id,
        prop2: 'Точки входа',
      },
    });
  }

  sendEventMoreClick(themeId: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName: { [ActionType.ClickMore]: `${themeId}` },
        goalName: GoalName.ClickMore,
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this._blockName,
        goalName: 'ClickShowcase',
        actionType: 'Клик',
        prop1: `${themeId}`,
        prop2: 'Показать еще',
      },
    });
  }

  sendEventNewsClick(id: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName: { [ActionType.ClickNews]: `${id}` },
        goalName: GoalName.ClickNews,
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this._blockName,
        goalName: 'ClickShowcase',
        actionType: ActionType.Click,
        prop1: `${id}`,
        prop2: 'Блок сюжета',
      },
    });
  }

  sendEventThemeHeaderClick(id: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName: { [ActionType.ClickMoreTop]: `${id}` },
        goalName: GoalName.ClickMoreTop,
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this._blockName,
        goalName: 'ClickShowcase',
        actionType: 'Клик',
        prop1: `${id}`,
        prop2: 'Заголовок блока сюжета',
      },
    });
  }

  sendEventTitleClick(id: number): void {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        valueName: { [ActionType.ClickOne]: `${id}` },
        goalName: GoalName.ClickOne,
      },
    });
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this._blockName,
        goalName: 'ClickShowcase',
        actionType: ActionType.Click,
        prop1: `${id}`,
        prop2: 'Заголовок',
      },
    });
  }
}
