


































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { LinkTargetVM } from '@jtnews/shared/seedwork/frontend/application';

import { UiImage } from '../../ui-image';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';
import { UiViqeoVideo } from '../../ui-viqeo-video';

import type { NewsSImage, NewsSStatistic, NewsSVideo } from './ui-news-s.contract';

@Component({
  components: {
    UiViqeoVideo,
    UiNewsCardLabels,
    UiObserveVisibility,
    UiStatistic,
    UiImage,
  },
  name: 'UiNewsS',
})
export default class UiNewsS extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  url!: string;

  @Prop({
    type: String as () => LinkTargetVM,
    required: true,
  })
  target!: LinkTargetVM;

  @Prop({
    type: Object as () => NewsSImage,
    required: true,
  })
  image!: NewsSImage;

  @Prop({
    type: Object as () => NewsSVideo,
    default: null,
  })
  video!: NewsSVideo;

  @Prop({
    type: Object as () => NewsSStatistic,
    required: true,
  })
  statistic!: NewsSStatistic;

  @Prop({
    type: Boolean,
    default: false,
  })
  isBookmarked: boolean;

  get hasVideo() {
    return guardUnspecified(this.video);
  }

  blockShown() {
    this.$emit('blockShown');
  }

  commentClicked(event: Event) {
    this.$emit('commentClicked', event);
  }

  bookmarkClicked(event: Event) {
    this.$emit('bookmarkClicked', event);
  }

  videoPlayed() {
    this.$emit('videoPlayed');
  }

  articleClicked(place: string) {
    this.$emit('articleClicked', place);
  }
}
