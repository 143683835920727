




import { Regions } from '@smh/projects/regions';
import { Component, Vue, Watch, Inject } from 'vue-property-decorator';

import { PAGE_STORE_FACADE_KEY, PageStoreFacade } from '../../../../application/src';

import { CONFIG_DATA } from './config';

@Component({
  name: 'AdvInfinityFeed',
})
export default class AdvInfinityFeed extends Vue {
  @Inject(PAGE_STORE_FACADE_KEY) pageStoreFacade!: PageStoreFacade;

  observerOptions = {
    root: null,
    rootMargin: '1100px',
    threshold: 0,
  };
  observer: IntersectionObserver | null = null;
  isViewPort = false;

  get isMobile() {
    return this.pageStoreFacade.isMobile;
  }

  get regionId(): Regions {
    return this.pageStoreFacade.regionId;
  }

  get isRender() {
    return this.blockId !== '';
  }

  get blockId(): string {
    const data = CONFIG_DATA[this.regionId];
    const deviceType = this.isMobile ? 'mobile' : 'desktop';

    return data?.[deviceType] || '';
  }

  @Watch('isViewPort')
  isViewPortChanged(value: boolean) {
    if (value) {
      this.init();
      this.observer?.disconnect();
    }
  }

  mounted() {
    this.initObserver();
  }

  initObserver() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.isViewPort = entry.isIntersecting;
      });
    }, this.observerOptions);

    // На dev-средах бажит без проверки
    if (this.$el.tagName) {
      this.observer.observe(this.$el);
    }
  }

  init() {
    window.yaContextCb = window.yaContextCb || [];
    window.yaContextCb.push(() => {
      window.Ya.Context.AdvManager.render({
        blockId: this.blockId,
        renderTo: 'adv-infinity-feed',
        type: 'feed',
        statId: 500,
      });
    });
  }
}
