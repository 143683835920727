import type { IArchiveArticleAnalyticsService } from '@jtnews/records/frontend/domain';
import {
  BlockUseCase,
  IBlockUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import type {
  BlockUseCaseConfig,
  IBookmarksUseCase,
} from '@jtnews/shared/seedwork/frontend/application';
import type { BlockPlaceRepositoryConfig } from '@jtnews/shared/seedwork/frontend/domain';

import type { ArchiveArticleVM } from './archive-articles.vm';

type ArticleClick = {
  data: ArchiveArticleVM;
  clickPlace: string;
};

type TaxonomyType = 'rubric' | 'format' | 'theme';

export const ARCHIVE_ARTICLES_USE_CASE_KEY = 'archive_articles_use_case_key';

export interface IArchiveArticlesUseCase extends IBlockUseCase {
  hasUserBookmark(articleId: number): boolean;
  processArticleClick(input: ArticleClick): void;
  processBlockShown(data: ArchiveArticleVM): void;
  processBookmarkClick(articleId: number): void;
  processCommentClick(data: ArchiveArticleVM): void;
  processTaxonomyClick(id: number, type: TaxonomyType): void;
}

export type ArchiveArticlesUseCaseConfig = BlockUseCaseConfig & {
  deps: {
    analyticsService: IArchiveArticleAnalyticsService;
    bookmarkUseCase: IBookmarksUseCase;
  };
};

export class ArchiveArticlesUseCase
  extends BlockUseCase
  implements IArchiveArticlesUseCase
{
  private readonly _analyticService: IArchiveArticleAnalyticsService;
  private readonly _bookmarkUseCase: IBookmarksUseCase;

  constructor(config: ArchiveArticlesUseCaseConfig) {
    super(config);

    const {
      deps: { analyticsService, bookmarkUseCase },
    } = config;

    this._analyticService = analyticsService;
    this._bookmarkUseCase = bookmarkUseCase;
  }

  public setBlockPlace(blockPlaceConfig: BlockPlaceRepositoryConfig) {
    this._analyticService.setBlockPlace(blockPlaceConfig);
  }

  public hasUserBookmark(articleId: number) {
    return this._bookmarkUseCase.hasUserBookmark(articleId);
  }

  public processCommentClick(data: ArchiveArticleVM): void {
    this._analyticService.sendEventCommentClick(data.id);
  }

  public processArticleClick(input: ArticleClick): void {
    this._analyticService.sendEventArticleClick(input.data.id);
  }

  public processBlockShown(data: ArchiveArticleVM): void {
    this._analyticService.sendEventBlockShown(data.id);
  }

  processTaxonomyClick(id: number, type: TaxonomyType): void {
    this._analyticService.sendEventTaxonomyClick(id, type);
  }

  public processBookmarkClick(articleId: number): void {
    void this._bookmarkUseCase.toggle({
      articleId,
      type: 'record',
    });
  }
}
