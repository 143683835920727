






















































import { guardEmptyArray } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { LinkTargetVM } from '@jtnews/shared/seedwork/frontend/application';

import { UiImage } from '../../ui-image';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type {
  TaxonomyClickEvent,
  NewsMainImage,
  NewsMainStatistic,
  NewsMainFormat,
  NewsMainRubric,
  NewsMainTheme,
} from './ui-news-main.contract';

@Component({
  components: {
    UiNewsCardLabels,
    UiObserveVisibility,
    UiStatistic,
    UiImage,
  },
  name: 'UiNewsMain',
})
export default class UiNewsMain extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  subtitle!: string;

  @Prop({
    type: String,
    required: true,
  })
  url!: string;

  @Prop({
    type: String as () => LinkTargetVM,
    required: true,
  })
  target!: LinkTargetVM;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullHeight!: boolean;

  @Prop({
    type: Object as () => NewsMainImage,
    required: true,
  })
  image!: NewsMainImage;

  @Prop({
    type: Object as () => NewsMainStatistic,
    required: true,
  })
  statistic!: NewsMainStatistic;

  @Prop({
    type: Boolean,
    default: false,
  })
  isOnline: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasImageMark: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasVideoMark: boolean;

  @Prop({
    type: Array as () => NewsMainFormat[],
    default: () => [],
  })
  formats: NewsMainFormat[];

  @Prop({
    type: Array as () => NewsMainRubric[],
    default: () => [],
  })
  rubrics: NewsMainRubric[];

  @Prop({
    type: Array as () => NewsMainTheme[],
    default: () => [],
  })
  themes: NewsMainTheme[];

  @Prop({
    type: Boolean,
    default: false,
  })
  isBookmarked: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isMobile: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isTablet: boolean;

  get labelsAlignment() {
    return this.isMobile || this.isTablet ? 'left' : 'center';
  }

  get labels() {
    return guardEmptyArray(this.formats) ? this.formats : this.rubrics;
  }

  articleClicked() {
    this.$emit('articleClicked');
  }

  taxonomyClicked(event: TaxonomyClickEvent) {
    this.$emit('taxonomyClicked', event);
  }

  commentClicked(event: Event) {
    this.$emit('commentClicked', event);
  }

  bookmarkClicked(event: Event) {
    this.$emit('bookmarkClicked', event);
  }

  blockShown() {
    this.$emit('blockShown');
  }
}
