import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

enum ActionType {
  Click = 'Клик',
  View = 'Просмотр',
  AllNews = 'Все видео (кнопка)',
}

enum GoalName {
  Click = 'clickDayReview',
  ClickHotNews = 'clickExpressNews',
  ViewHotNews = 'viewExpressNews',
  View = 'viewDayReview',
  ViewBlock = 'viewDayReviewBlock',
}

type Article = {
  id: number;
  place: number;
  isHotNews: boolean;
  isCommercial: boolean;
};

export type PictureDayAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface IPictureDayAnalyticsService extends IAnalyticsService {
  sendEventArticleClick(tabName: string, item: Article): void;
  sendEventBlockShown(tabName: string): void;
  sendEventAllNewsClick(tabName: string): void;
  sendEventItemShown(tabName: string, item: Article): void;
  sendEventHotNewsClick(articleId: number): void;
  sendEventHotNewsShown(articleId: number): void;
  sendEventTabClick(tabName: string): void;
}

export class PictureDayAnalyticsService
  extends AnalyticsService
  implements IPictureDayAnalyticsService
{
  private readonly _tabs: string[] = ['Главное'];
  constructor(config: PictureDayAnalyticsServiceConfig) {
    super(config);
  }

  sendEventArticleClick(tabName: string, item: Article): void {
    if (item.isHotNews) {
      this.sendEventHotNewsClick(item.id);
      return;
    }

    const type = item.isCommercial ? 'Рекламный' : 'Редакционный';
    const valueName = {
      [ActionType.Click]: {
        [item.place]: {
          [type]: item.id.toString(),
        },
      },
    };
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: `Картина дня (${tabName})`,
        goalName: GoalName.Click,
        pageName: this.pageName,
        valueName,
      },
    });
  }

  sendEventBlockShown(tabName: string): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: `Картина дня (${tabName})`,
        goalName: GoalName.ViewBlock,
        pageName: this.pageName,
        valueName: ActionType.View,
      },
    });
  }
  sendEventItemShown(tabName: string, item: Article): void {
    const type = item.isCommercial ? 'Рекламный' : 'Редакционный';
    const valueName = {
      [ActionType.View]: {
        [item.place]: {
          [type]: item.id.toString(),
        },
      },
    };
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: `Картина дня (${tabName})`,
        goalName: GoalName.View,
        pageName: this.pageName,
        valueName,
      },
    });

    if (item.isHotNews) {
      this.sendEventHotNewsShown(item.id);
    }
  }

  sendEventHotNewsClick(articleId: number): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Срочная новость',
        goalName: GoalName.ClickHotNews,
        pageName: this.pageName,
        valueName: { [ActionType.Click]: `${articleId}` },
      },
    });
  }

  sendEventHotNewsShown(articleId: number): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: 'Срочная новость',
        goalName: GoalName.ViewHotNews,
        pageName: this.pageName,
        valueName: { [ActionType.View]: `${articleId}` },
      },
    });
  }

  sendEventTabClick(tabName: string): void {
    if (this._checkTabWasActive(tabName)) {
      return;
    }
    this._tabs.push(tabName);
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: `Картина дня (${tabName})`,
        goalName: GoalName.ViewBlock,
        pageName: this.pageName,
        valueName: ActionType.View,
      },
    });
  }
  sendEventAllNewsClick(tabName: string): void {
    this.analytics.send({
      event: {
        blockType: this.blockPlace,
        fieldType: `Картина дня (${tabName})`,
        goalName: GoalName.Click,
        pageName: this.pageName,
        valueName: ActionType.AllNews,
      },
    });
  }
  private _checkTabWasActive(tabName: string): boolean {
    return this._tabs.includes(tabName);
  }
}
