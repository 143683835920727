
























import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import {
  ALL_NEWS_BUTTON_USE_CASE_KEY,
  type IAllNewsButtonUseCase,
} from '@jtnews/home/frontend/application';
import { UiButton, UiObserveVisibility } from '@jtnews/shared/ui';

@Component({
  name: 'AllNewsButton',
  components: { UiObserveVisibility, UiButton },
})
export default class AllNewsButton extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  isMobile: boolean;
  @Prop({
    type: Boolean,
    required: true,
  })
  isTablet: boolean;

  @Inject(ALL_NEWS_BUTTON_USE_CASE_KEY)
  private readonly _useCase!: IAllNewsButtonUseCase;

  isVisible = false;

  get link() {
    return { url: '/text/', title: 'Все новости' };
  }

  get isSticky() {
    return this.isMobile && this.isVisible && !this.isTablet;
  }

  buttonVisible() {
    this.isVisible = true;
    this._useCase.processButtonView();
  }

  processAllNewsClick() {
    this._useCase.processButtonClick();
  }
}
