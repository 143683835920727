import type {
  AnalyticsServiceConfig,
  IAnalyticsService,
} from '@jtnews/shared/seedwork/frontend/domain';
import { AnalyticsService } from '@jtnews/shared/seedwork/frontend/domain';

enum GoalName {
  Click = 'clickShowcase',
  View = 'viewShowcase',
}

export type AllNewsButtonAnalyticsServiceConfig = AnalyticsServiceConfig;

export interface IAllNewsButtonAnalyticsService extends IAnalyticsService {
  sendEventClick(): void;
  sendEventView(): void;
}

export class AllNewsButtonAnalyticsService
  extends AnalyticsService
  implements IAllNewsButtonAnalyticsService
{
  private readonly _blockName = 'Витрина';

  constructor(config: AllNewsButtonAnalyticsServiceConfig) {
    super(config);
  }

  sendEventClick() {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.Click,
        valueName: { ['Все новости (кнопка)']: 'Клик' },
      },
    });
  }
  sendEventView() {
    this.analytics.send({
      event: {
        pageName: this.pageName,
        blockType: this.blockPlace,
        fieldType: this._blockName,
        goalName: GoalName.View,
        valueName: { ['Все новости (кнопка)']: 'Просмотр' },
      },
    });
  }
}
